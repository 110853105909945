import React, { useEffect, useState, useMemo } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import dayjs from 'dayjs';

const parseConcatenatedResults = (results) => {
  const { MeterData, AdditionalMeterDetails } = results;

  const dataByMeter = MeterData.reduce((acc, item) => {
    const { MeterID, TimeStamp, IntervalNo, Consumption, Actual } = item;
    const dateTime = dayjs(TimeStamp).add(IntervalNo * 30, 'minute').toISOString();
    if (!acc[MeterID]) {
      const meterDetail = AdditionalMeterDetails.find(detail => 
        Array.isArray(detail.MeterTreeId) 
          ? detail.MeterTreeId.includes(MeterID) 
          : detail.MeterTreeId === MeterID
      );
      acc[MeterID] = {
        meterId: MeterID,
        meterName: meterDetail ? meterDetail.MeterName : `Meter ${MeterID}`,
        units: 'kWh', // Assuming consumption is in kWh
        data: [],
        actual: [],
        timestamps: [],
      };
    }
    acc[MeterID].data.push({ value: Consumption, time: dateTime });
    acc[MeterID].actual.push(Actual);
    acc[MeterID].timestamps.push(dateTime);
    return acc;
  }, {});

  const allTimestamps = Array.from(new Set(MeterData.map(item => dayjs(item.TimeStamp).add(item.IntervalNo * 30, 'minute').toISOString()))).sort();
  const start = dayjs(allTimestamps[0]);
  const end = dayjs(allTimestamps[allTimestamps.length - 1]);
  const fullRangeTimestamps = [];
  let current = start;

  while (current.isBefore(end) || current.isSame(end)) {
    fullRangeTimestamps.push(current.toISOString());
    current = current.add(30, 'minute');
  }

  Object.values(dataByMeter).forEach(meter => {
    const timestampSet = new Set(meter.timestamps);
    meter.data = fullRangeTimestamps.map(ts => {
      const index = meter.timestamps.indexOf(ts);
      return index > -1 ? meter.data[index].value : null;
    });
    meter.actual = fullRangeTimestamps.map(ts => {
      const index = meter.timestamps.indexOf(ts);
      return index > -1 ? meter.actual[index] : null;
    });
    meter.timestamps = fullRangeTimestamps;
  });

  return Object.values(dataByMeter);
};

const MeterDataChart = ({ concatenatedResults, colors }) => {
  const [chartOptions, setChartOptions] = useState({});

  const metersData = useMemo(() => {
    if (concatenatedResults && concatenatedResults.MeterData) {
      return parseConcatenatedResults(concatenatedResults);
    }
    return [];
  }, [concatenatedResults]);

  const categories = useMemo(() => {
    return metersData.length > 0 ? metersData[0].timestamps : [];
  }, [metersData]);

  const series = useMemo(() => {
    return metersData.map(meter => ({
      name: meter.meterName,
      data: meter.data.map((value, index) => ({
        y: value,
        dashStyle: meter.actual[index] === 0 ? 'Dash' : 'Solid',
        dataGrouping: {
          enabled: true
        }
      })),
      tooltip: {
        valueSuffix: ` ${meter.units}`
      }
    }));
  }, [metersData]);

  const getOrdinal = (n) => {
    const s = ["th", "st", "nd", "rd"];
    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  };

  const calculateStepForDays = (categories) => {
    const uniqueDays = Array.from(new Set(categories.map(date => dayjs(date).format('YYYY-MM-DD'))));
    return Math.ceil(categories.length / uniqueDays.length);
  };

  useEffect(() => {
    setChartOptions({
      title: { text: '' },
      rangeSelector: { enabled: false, inputEnabled: false },
      navigator: {
        maskFill: 'rgba(48,132,74, 0.2)',
        series: {
          color: colors.greenAccent[400]
        }
      },
      zoomType: 'none',
      xAxis: {
        categories,
        title: { text: 'Time' },
        labels: {
          formatter: function() {
            const date = dayjs(this.value);
            const dayWithSuffix = getOrdinal(date.date());
            return `${date.format('MMM')} ${dayWithSuffix} ${date.format('YY')}`;
          },
          step: calculateStepForDays(categories)
        }
      },
      yAxis: { title: { text: 'Consumption' } },
      series,
      chart: {
        backgroundColor: "rgba(0,0,0,0)",
        height: "60%",
        type: 'line'
      },
      legend: {
        enabled: true,
        align: 'center',
        verticalAlign: 'bottom',
        layout: 'horizontal',
        navigation: {
          activeColor: colors.greenAccent[400],
        }
      },
      tooltip: {
        shared: true,
        formatter: function () {
          return this.points.reduce((s, point) => {
            return `${s}<br/><span style="color:${point.series.color}">\u25CF</span> ${point.series.name}: <b>${point.y} ${point.series.userOptions.tooltip.valueSuffix}</b>`;
          }, `<b>${this.x}</b>`);
        }
      }
    });
  }, [categories, series, colors]);

  return (
    <HighchartsReact colors={colors} constructorType={'stockChart'} highcharts={Highcharts} options={chartOptions} width="100%" height="40vh"/>
  );
};

export default MeterDataChart;
