import React, { useState, useMemo, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';
import Paper from '@mui/material/Paper';
import { useGoogleMapsLoader } from '../../googleMapsLoader';

const autocompleteService = { current: null };

const GoogleMapsAutocomplete = ({ onPlaceSelected }) => {
  const { isLoaded } = useGoogleMapsLoader();
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);

  const fetch = useMemo(
    () =>
      debounce((request, callback) => {
        if (autocompleteService.current) {
          autocompleteService.current.getPlacePredictions(request, callback);
        }
      }, 400),
    [],
  );

  useEffect(() => {
    let active = true;

    if (isLoaded && !autocompleteService.current) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }

    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(
      {
        input: inputValue,
        componentRestrictions: { country: 'uk' }, // Restrict to UK
      },
      (results) => {
        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      },
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, isLoaded]);

  const renderOption = (props, option) => {
    const matches =
      option.structured_formatting.main_text_matched_substrings || [];

    const parts = parse(
      option.structured_formatting.main_text,
      matches.map((match) => [match.offset, match.offset + match.length]),
    );

    return (
      <li {...props}>
        <Grid container alignItems="center">
          <Grid item sx={{ display: 'flex', width: "100%" }}>
            <LocationOnIcon sx={{ color: 'text.secondary' }} />
          </Grid>
          <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
            {parts.map((part, index) => (
              <Box
                key={index}
                component="span"
                sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
              >
                {part.text}
              </Box>
            ))}
            <Typography variant="body2" color="text.secondary">
              {option.structured_formatting.secondary_text}
            </Typography>
          </Grid>
        </Grid>
      </li>
    );
  };

  const handleChange = (event, newValue) => {
    setOptions(newValue ? [newValue, ...options] : options);
    setValue(newValue);
    if (newValue) {
      const placeId = newValue.place_id;
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ placeId }, (results, status) => {
        if (status === "OK" && results[0]) {
          const location = results[0].geometry.location;
          onPlaceSelected({ lat: location.lat(), lng: location.lng() });
        }
      });
    }
  };

  return (
    <Autocomplete
      id="google-map-demo"
      sx={{ width: "100%" }}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      PaperComponent={(props) => <Paper {...props} style={{ zIndex: 1300 }} />}
      onChange={handleChange}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Search address..." fullWidth />
      )}
      renderOption={renderOption}
    />
  );
};

export default GoogleMapsAutocomplete;
