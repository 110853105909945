import { Box, Typography } from "@mui/material";
import Header from "../../components/Global/Header";
import MeterDataChart from '../../components/MeterData/meterDataChart';

const MeterData = ({ meterDataVariable, colors }) => {

  return (
    <Box m="20px" width="45vw" height="80vh" >
      <Header title="Meter Data" subtitle="View or Export Meter Data"  colors={colors}/>
      {meterDataVariable && meterDataVariable.MeterData ? (
        <MeterDataChart concatenatedResults={meterDataVariable} width="100%" height="50vh" colors={colors}/>   
      ) : (
        <Typography>Select a Meter to Begin.</Typography>
      )}
    </Box>
  );
};

export default MeterData;
