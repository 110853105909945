import { fetchAuthSession } from 'aws-amplify/auth';
import notification from '../components/Global/Toast';

const DeleteLocationGroups = {
  deleteGroup: async (locationGroupId) => {
    console.log("Deleting Location Group...");
    try {
      const session = await fetchAuthSession();
      const token = session.tokens.accessToken || session.tokens.idToken;

      const url = 'https://www.data.mygreenvision.net/DeleteLocationGroup/';

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          locationGroupId: locationGroupId
        }),
      });

      const data = await response.json();

      if (!response.ok || data.statusCode !== 200) {
        notification('error', "Failed to delete location");
        throw new Error("Code: ", data.statusCode);
      }
     
      console.log(data);
      notification('success', 'Location group deleted successfully');
      return data;

    } catch (error) {
      console.error('Error deleting location group:', error);
      notification('error', 'Failed to delete location');
      throw error;
    }
  }
};

export default DeleteLocationGroups;
