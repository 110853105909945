import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Box,
  useTheme,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  IconButton,
} from "@mui/material";
import Header from "../../components/Global/Header";
import Alarms from "../../data/alarmsQuery";
import { GoogleMap, Marker, OverlayView } from "@react-google-maps/api";
import { useGoogleMapsLoader } from "../../googleMapsLoader";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CustomTablePagination from "../../components/Global/CustomTablePagination";
import { format } from "date-fns";
import Spinner from "../../components/Global/Spinner";

const Alerts = ({ colors }) => {
  const theme = useTheme();
  const { isLoaded } = useGoogleMapsLoader();
  const [alarms, setAlarms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [uniqueLocations, setUniqueLocations] = useState([]);
  const tableContainerRef = useRef(null);
  const mapRef = useRef(null);
  const [hoveredMarker, setHoveredMarker] = useState(null);
  const [mapCenter, setMapCenter] = useState({ lat: 53.3811, lng: -1.4701 });
  const [zoomLevel, setZoomLevel] = useState(7);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [hoveredRow, setHoveredRow] = useState(null);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return format(date, "dd/MM/yyyy HH:mm");
  };

  const fetchAlarms = useCallback(async () => {
    setLoading(true);
    try {
      const response = await Alarms.getAlarmData();
      const alarmData = response.alarms || response;
      if (Array.isArray(alarmData)) {
        setAlarms(alarmData);
        const uniqueLocs = [
          ...new Map(
            alarmData.map((alarm) => [alarm.LocationGroupID, alarm])
          ).values(),
        ].filter((alarm) => alarm.Lat && alarm.Lng);
        setUniqueLocations(uniqueLocs);
        calculateCenterAndZoom(uniqueLocs);
      } else {
        console.error("Alarm data is not an array:", alarmData);
      }
    } catch (error) {
      console.error("Error fetching alarms:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchAlarms();
  }, [fetchAlarms, theme.palette.mode]);

  useEffect(() => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop =
        tableContainerRef.current.scrollHeight;
    }
  }, [alarms]);

  const filteredAlarms = alarms.filter((alarm) =>
    alarm.MeterName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const paginatedAlarms = filteredAlarms.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMarkerMouseOver = (marker) => {
    setHoveredMarker(marker);
  };

  const handleMarkerMouseOut = () => {
    setHoveredMarker(null);
  };

  const handleRowMouseEnter = (locationGroupID) => {
    const marker = uniqueLocations.find(
      (location) => location.LocationGroupID === locationGroupID
    );
    if (marker) {
      setHoveredMarker(marker);
    }
    setHoveredRow(locationGroupID);
  };

  const handleRowMouseLeave = () => {
    setHoveredMarker(null);
    setHoveredRow(null);
  };

  const renderTooltip = () => {
    if (hoveredMarker) {
      const tooltipBackgroundColor =
        theme.palette.mode === "light"
          ? colors.primary[400]
          : colors.primary[900];
      const tooltipTextColor = colors.primary[100];

      const tooltipStyle = {
        position: "absolute",
        backgroundColor: tooltipBackgroundColor,
        color: tooltipTextColor,
        padding: "10px",
        borderRadius: "8px",
        boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
        pointerEvents: "none",
        transform: "translate(-50%, -150%)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        position: "relative",
        minWidth: "max-content",
        maxWidth: "300px",
        whiteSpace: "nowrap",
      };

      const tooltipPointerStyle = {
        content: '""',
        position: "absolute",
        bottom: "-18px",
        left: "50%",
        transform: "translateX(-50%)",
        borderWidth: "10px",
        borderStyle: "solid",
        borderColor: tooltipBackgroundColor.concat(
          " transparent transparent transparent"
        ),
      };

      return (
        <OverlayView
          position={{
            lat: parseFloat(hoveredMarker.Lat),
            lng: parseFloat(hoveredMarker.Lng),
          }}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <div style={tooltipStyle}>
            <Typography fontWeight="800" variant="subtitle2">
              {hoveredMarker.LocationGroupName || "Unknown"}
            </Typography>
            <div style={tooltipPointerStyle} />
          </div>
        </OverlayView>
      );
    }
    return null;
  };

  const getAcknowledgedIcon = (isHovered) => {
    return isHovered ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />;
  };

  const calculateCenterAndZoom = (locations) => {
    if (locations.length === 0) return;

    let validLocations = locations.filter(
      (location) =>
        location.Lat !== undefined &&
        location.Lng !== undefined &&
        !isNaN(location.Lat) &&
        !isNaN(location.Lng)
    );

    if (validLocations.length === 0) {
      console.error("No valid locations to calculate center and zoom");
      return;
    }

    let latSum = 0;
    let lngSum = 0;
    let minLat = Infinity;
    let maxLat = -Infinity;
    let minLng = Infinity;
    let maxLng = -Infinity;

    validLocations.forEach((location) => {
      const lat = parseFloat(location.Lat);
      const lng = parseFloat(location.Lng);

      latSum += lat;
      lngSum += lng;

      if (lat < minLat) minLat = lat;
      if (lat > maxLat) maxLat = lat;
      if (lng < minLng) minLng = lng;
      if (lng > maxLng) maxLng = lng;
    });

    const avgLat = latSum / validLocations.length;
    const avgLng = lngSum / validLocations.length;

    const latDiff = maxLat - minLat;
    const lngDiff = maxLng - minLng;
    const maxDiff = Math.max(latDiff, lngDiff);

    let zoom;
    if (maxDiff < 0.01) {
      zoom = 16;
    } else if (maxDiff < 0.1) {
      zoom = 12;
    } else if (maxDiff < 0.5) {
      zoom = 9;
    } else if (maxDiff < 1) {
      zoom = 8;
    } else if (maxDiff < 10) {
      zoom = 7;
    } else {
      zoom = 3;
    }

    setMapCenter({ lat: avgLat, lng: avgLng });
    setZoomLevel(zoom);
  };

  const onLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  return (
    <Box m="20px" width="100%">
      <Header title="Alerts" subtitle="View System Alerts" colors={colors} />
      <Box display="flex" width="100%" height="80vh">
      <Box
  width="50%"
  height="100%"
  overflow="hidden"
  sx={{ borderRadius: "5px" }}
  backgroundColor={colors.grey[900]}
>
  {loading ? (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Spinner colors={colors} />
    </Box>
  ) : (
    <Box height="100%">
      <Box
        width="100%"
        height="5%"
        padding="8px"
        backgroundColor={colors.greenAccent[400]}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography sx={{ fontWeight: "800" }}>Alerts</Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        height="95%"
        overflow="none"
      >
        <TableContainer
          ref={tableContainerRef}
          style={{
            flex: 1,
            overflowY: "auto",
          }}
        >
          {paginatedAlarms.length > 0 ? (
            <Table size="small" stickyHeader>
              <TableBody>
                {paginatedAlarms.map((alarm, index) => (
                  <TableRow
                    key={index}
                    onMouseEnter={() => handleRowMouseEnter(alarm.LocationGroupID)}
                    onMouseLeave={handleRowMouseLeave}
                    sx={{
                      "&:hover": {
                        backgroundColor: colors.grey[900],
                        cursor: "pointer",
                      },
                    }}
                  >
                    <TableCell sx={{ padding: "3px 0 3px 5px" }}>
                      <IconButton sx={{ padding: 0 }}>
                        {getAcknowledgedIcon(hoveredRow === alarm.LocationGroupID)}
                      </IconButton>
                    </TableCell>
                    <TableCell sx={{ padding: "3px 0 3px 0" }}>
                      {formatTimestamp(alarm.TimeStamp)}
                    </TableCell>
                    <TableCell
                      sx={{ padding: "3px" }}
                      dangerouslySetInnerHTML={{ __html: alarm.AlarmText }}
                    ></TableCell>
                    <TableCell
                      sx={{ padding: "3px 0 3px 0", fontWeight: "800" }}
                    >
                      {alarm.LocationGroupName}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Box
              width="100%"
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography>No Alerts Recorded</Typography>
            </Box>
          )}
        </TableContainer>
        {paginatedAlarms.length > 0 && (
          <Box
            sx={{
              position: "sticky",
              bottom: 0,
              backgroundColor: colors.grey[800],
            }}
          >
            <CustomTablePagination
              count={filteredAlarms.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              actionDisabled={false}
              onSaveClick={() => {}}
              handleOpen={() => {}}
            />
          </Box>
        )}
      </Box>
    </Box>
  )}
</Box>


        <Box ml="2vh" />

        <Box
          width="50%"
          height="100%"
          overflow="hidden"
          sx={{ borderRadius: "5px" }}
        >
          {isLoaded && (
            <GoogleMap
              mapContainerStyle={{ width: "100%", height: "100%" }}
              center={mapCenter}
              zoom={zoomLevel}
              options={{
                mapTypeId: "hybrid",
                disableDefaultUI: true,
              }}
              onLoad={onLoad}
            >
              {uniqueLocations.map((location, index) => {
                const position = {
                  lat: parseFloat(location.Lat),
                  lng: parseFloat(location.Lng),
                };
                return (
                  <Marker
                    key={index}
                    position={position}
                    onMouseOver={() => handleMarkerMouseOver(location)}
                    onMouseOut={handleMarkerMouseOut}
                  />
                );
              })}
              {renderTooltip()}
            </GoogleMap>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Alerts;
