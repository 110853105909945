import { fetchAuthSession } from 'aws-amplify/auth';
import notification from '../components/Global/Toast';

const ManageLocationRoots = {
  manageRoots: async (locationRoots) => {
    console.log("Managing Location Roots...");
    try {
      const session = await fetchAuthSession();
      const token = session.tokens.accessToken || session.tokens.idToken;

      const url = 'https://www.data.mygreenvision.net/ManageLocationRoots/';

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(locationRoots),
      });

      const data = await response.json();

      if (!response.ok || data.statusCode !== 200) {
        notification('error', "Failed to save location roots");
        throw new Error("Code: ", data.statusCode);
      }

      console.log(data);
      notification('success', 'Location roots saved successfully');
      return data;

    } catch (error) {
      console.error('Error saving location roots:', error);
      notification('error', 'Failed to save location roots');
      throw error;
    }
  }
};

export default ManageLocationRoots;
