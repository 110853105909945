import React, { useState, useEffect } from 'react';
import { Tooltip, Drawer, Box, List, ListItem, ListItemIcon, ListItemText, Typography, Divider, Menu as MuiMenu, MenuItem as MuiMenuItem } from '@mui/material';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import DisplaySettingsOutlinedIcon from '@mui/icons-material/DisplaySettingsOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import { signOut, getCurrentUser } from 'aws-amplify/auth';
import Zoom from '@mui/material/Zoom';
import Logo from '../../components/Global/logo'
import HubIcon from '@mui/icons-material/Hub';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';

const drawerWidth = 240;

const Sidebar = ({ colors }) => {
  const [username, setUsername] = useState(null);
  const [selected, setSelected] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const navigate = useNavigate();
  const location = useLocation();

  const preferencesRedirect = () => {
    navigate('/preferences');
  };

  const SidebarItem = ({ title, to, icon, disabled }) => (
    <Tooltip title={disabled ? "Coming Soon" : ""} TransitionComponent={Zoom} TransitionProps={{ timeout: 400 }} followCursor>
      <ListItem
        button={!disabled}
        selected={selected === to}
        onClick={() => !disabled && setSelected(to)}
        component={Link}
        to={disabled ? "#" : to}
        sx={{
          fontWeight: 'bold',
          backgroundColor: disabled ? colors.grey[800] : 'inherit',
          cursor: disabled ? 'not-allowed' : 'pointer',
          color: disabled ? colors.grey[500] : 'inherit',
          '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: !disabled ? colors.greenAccent[400] : colors.grey[800],
            color: !disabled ? 'white' : 'inherit',
          },
          '&:hover': {
            backgroundColor: !disabled ? colors.greenAccent[400] : colors.grey[800],
            color: !disabled ? 'white' : 'inherit',
          },
        }}
      >
        <ListItemIcon sx={{ color: 'inherit', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{icon}</ListItemIcon>
        <ListItemText primary={<Typography fontWeight="bold" variant="body1" sx={{ color: 'inherit', padding: '0' }}>{title}</Typography>} />
      </ListItem>
    </Tooltip>
  );

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await getCurrentUser();
        setUsername(user ? user.username : null);
      } catch (error) {
        console.error('error fetching user', error);
      }
    };

    fetchUser();
    setSelected(location.pathname);  // Update selected state based on current path
  }, [location.pathname]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/');
    } catch (error) {
      console.error('error signing out', error);
    }
  };

  const drawer = (
    <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%" backgroundColor={colors.primary[400]}>
      <Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          padding="20px 0 20px 0"
        >
          <Box sx={{ padding: "5px" }}>
            <Logo colors={colors}/>
          </Box>
        </Box>
        <List sx={{ paddingTop: "0" }}>
          <SidebarItem title="Dashboard" to="/" icon={<HomeOutlinedIcon />} disabled={true} />
          <SidebarItem title="Meters" to="/meters" icon={<SpeedOutlinedIcon />} />
          <SidebarItem title="Meter Data" to="/meterdata" icon={<TimelineOutlinedIcon />} />
          <SidebarItem title="Meter Readings" to="/meterreadings" icon={<ReceiptOutlinedIcon />} />
          <SidebarItem title="Alerts" to="/alerts" icon={<NotificationsNoneOutlinedIcon />} />
          <SidebarItem title="Map" to="/map" icon={<MapOutlinedIcon />} />
          <SidebarItem title="Sub-Users" to="/subusers" icon={<SupervisorAccountOutlinedIcon />} />

          <SidebarItem title="Admin" to="/admin" icon={<HubIcon />} />
        </List>
      </Box>
      <Box>
        <Divider />
        <List sx={{ padding: '0'}}>
          <ListItem button onClick={handleMenu} sx={{ padding: '10px 0 10px 0', width: '100%', display: 'flex', justifyContent: 'center'}}>
            <ListItemIcon sx={{ color: 'inherit', display: 'flex', justifyContent: 'right' }}><PersonOutlinedIcon /></ListItemIcon>
            <ListItemText sx={{ paddingLeft: '10px' }} primary={<Typography fontWeight="bold" variant="body1" sx={{ color: 'inherit' }}>{username ? username.charAt(0).toUpperCase() + username.slice(1) : 'Username'}</Typography>} />
          </ListItem>
        </List>
        <MuiMenu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={openMenu}
          onClose={handleClose}
        >
          <MuiMenuItem onClick={handleClose}><ManageAccountsOutlinedIcon />My account</MuiMenuItem>
          <MuiMenuItem onClick={preferencesRedirect}><DisplaySettingsOutlinedIcon pr="5px" />Preferences</MuiMenuItem>
          <MuiMenuItem onClick={handleLogout}><LogoutOutlinedIcon />Logout</MuiMenuItem>
        </MuiMenu>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default Sidebar;
