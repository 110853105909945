// SummaryMap.js
import React, { useEffect, useState, useCallback } from 'react';
import { GoogleMap, Marker, OverlayView } from '@react-google-maps/api';
import { v4 as uuidv4 } from 'uuid';
import { useGoogleMapsLoader } from '../../googleMapsLoader'; // Import the loader utility
import { Box, Typography, useTheme } from "@mui/material";

const containerStyle = {
  width: '100%',
  height: '100%',
};

const SummaryMap = ({ data, colors }) => {
  const theme = useTheme();

  const defaultCenter = {
    lat: 53.45284754180988,
    lng: -2.5683444608174355,
  };

  const [meter, setMeter] = useState(defaultCenter);
  const [parsedData, setParsedData] = useState([]);
  const [hoveredMarker, setHoveredMarker] = useState(null);

  useEffect(() => {
    console.log("Component updated with new data", data);
    try {
      const parsed = JSON.parse(data.body);
      console.log('Parsed Data:', parsed);
      setParsedData(parsed.AdditionalMeterData || []);
      if (parsed.AdditionalMeterData.length > 0) {
        setMeter({
          lat: parsed.AdditionalMeterData[0].Lat || defaultCenter.lat,
          lng: parsed.AdditionalMeterData[0].Lng || defaultCenter.lng,
        });
      }
    } catch (error) {
      console.error('Error parsing data:', error);
    }
  }, [data]);

  const { isLoaded } = useGoogleMapsLoader(); // Use the loader utility

  const [map, setMap] = useState(null);

  const onLoad = useCallback((map) => {
    setMap(map);
    console.log('Map Loaded:', map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  useEffect(() => {
    if (map) {
      map.setCenter(meter);
      map.setZoom(17);
      map.panTo(meter);
      console.log('Map Centered to:', meter);
    }
  }, [map, meter]);

  const handleMarkerMouseOver = (marker) => {
    setHoveredMarker(marker);
  };

  const handleMarkerMouseOut = () => {
    setHoveredMarker(null);
  };

  const renderTooltip = () => {
    if (hoveredMarker) {
      const tooltipBackgroundColor = (theme.palette.mode === "light" ? colors.primary[400] : colors.primary[900]);
      const tooltipTextColor = colors.primary[100];

      const tooltipStyle = {
        backgroundColor: tooltipBackgroundColor,
        color: tooltipTextColor,
        padding: "10px",
        borderRadius: "8px",
        boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
        pointerEvents: "none",
        transform: "translate(-50%, -150%)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        position: "relative",
        minWidth: "max-content",
        maxWidth: "300px",
        whiteSpace: "nowrap",
      };

      const tooltipPointerStyle = {
        content: '""',
        position: "absolute",
        bottom: "-18px",
        left: "50%",
        transform: "translateX(-50%)",
        borderWidth: "10px",
        borderStyle: "solid",
        borderColor:
          tooltipBackgroundColor.concat(" transparent transparent transparent"),
      };
      return (
        <OverlayView
          position={{ lat: hoveredMarker.Lat, lng: hoveredMarker.Lng }}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <div style={tooltipStyle}>
            <Typography fontWeight="800" variant="subtitle2">
              {hoveredMarker.LocationGroup || "Unknown"}
            </Typography>
            <Box display="flex">
              <Typography variant="body2">Meters: </Typography>
              <Typography
                variant="body2"
                style={{ whiteSpace: "nowrap", marginLeft: "4px" }}
              >
                {hoveredMarker.ChildCount || "Unknown"}
              </Typography>
            </Box>
            <div style={tooltipPointerStyle} />
          </div>
        </OverlayView>
      );
    }
    return null;
  };
  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={meter}
      zoom={12}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{
        mapTypeId: 'hybrid',
        disableDefaultUI: true,
      }}
    >
      {parsedData.length > 0 && parsedData.map((item) => (
        <Marker
          key={uuidv4()}
          position={{ lat: item.Lat, lng: item.Lng }}
          onMouseOver={() => handleMarkerMouseOver(item)}
          onMouseOut={handleMarkerMouseOut}
        />
      ))}
      {renderTooltip()}
    </GoogleMap>
  ) : <></>;
}

export default React.memo(SummaryMap);
