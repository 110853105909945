import React, { useState, useContext } from "react";
import { Modal, Box, Typography, Button, CircularProgress } from "@mui/material";
import { PaymentOutlined } from "@mui/icons-material";
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { SubscriptionContext } from '../../App'; // Adjust the path as needed
import { toast } from 'react-toastify';

const PaymentProcessingModal = ({ open, onClose, tier, quantity, isAnnual, price }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { setIsSubscribed } = useContext(SubscriptionContext);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handlePayment = async () => {
    if (!stripe || !elements) return;

    setLoading(true);
    setErrorMessage('');
    try {
      const response = await fetch('https://data.mygreenvision.net/create-subscription', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          priceId: getPriceId(tier, isAnnual),
        }),
      });

      const session = await response.json();

      const { error } = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (error) {
        setErrorMessage(error.message);
        setLoading(false);
        toast.error("Error processing payment: " + error.message);
        return;
      }

      // Assuming the subscription is successful
      setIsSubscribed(true);
      toast.success("Subscription successful!");
    } catch (error) {
      setErrorMessage("Error processing payment: " + error.message);
      setLoading(false);
      toast.error("Error processing payment: " + error.message);
    }
  };

  const getPriceId = (tier, isAnnual) => {
    const priceMap = {
      1: isAnnual ? "price_annual_standard" : "price_monthly_standard",
      2: isAnnual ? "price_annual_advanced" : "price_monthly_advanced",
      3: isAnnual ? "price_annual_enterprise" : "price_monthly_enterprise",
    };
    return priceMap[tier];
  };

  const renewalDate = new Date();
  renewalDate.setFullYear(renewalDate.getFullYear() + (isAnnual ? 1 : 0));
  renewalDate.setMonth(renewalDate.getMonth() + (isAnnual ? 0 : 1));

  return (
    <Modal open={open} onClose={onClose}>
      <Box p={3} bgcolor="background.paper" borderRadius={3} mx="auto" my="20vh" maxWidth={500}>
        <Typography variant="h6" mb={2}>
          Enter Card Details
        </Typography>
        <Box mb={2}>
          <CardElement />
        </Box>
        {errorMessage && (
          <Typography variant="body2" color="error" mb={2}>
            {errorMessage}
          </Typography>
        )}
        <Typography variant="body1" mb={1}>
          Amount Due Today: £{(price * 1.2).toFixed(2)}
        </Typography>
        <Typography variant="body1" mb={2}>
          Renewal Date: {renewalDate.toDateString()}
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={handlePayment}
          startIcon={loading ? <CircularProgress size={24} /> : <PaymentOutlined />}
          fullWidth
          disabled={loading}
        >
          {loading ? "Processing..." : "Proceed"}
        </Button>
      </Box>
    </Modal>
  );
};

export default PaymentProcessingModal;
