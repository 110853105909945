import React from "react";
import { Box, Typography } from "@mui/material";
import {
  faSatelliteDish,
  faThumbsDown,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FindIcon from "../Global/utilityIcons";
import DiffIndicator from "../Global/DiffIndicator";

const AdditionalMeterDetails = ({ data, colors }) => {
  const parsedData = JSON.parse(data.body);
  const utilityId = parseInt(parsedData.AdditionalMeterData[0].UtilityID);
  const utilitySubtypeId = parseInt(parsedData.AdditionalMeterData[0].UtilitySubtypeID);
  const units = parsedData.AdditionalMeterData[0].Units;
  const year = Math.round(parsedData.AdditionalMeterData[0].YTDTotal);
  const month = Math.round(parsedData.AdditionalMeterData[0].MTDTotal);

  const co2Multiplier = (utilityId === 11 || utilitySubtypeId === 3 || utilitySubtypeId === 10 || utilitySubtypeId === 11) ? 0.36 : parsedData.AdditionalMeterData[0].CO2perkWh;

  const yearCarbon = Math.round(year * co2Multiplier);
  const monthCarbon = Math.round(month * co2Multiplier);

  const findUtilityResources = () => {
    if (utilitySubtypeId !== 3 && utilitySubtypeId !== 10 && utilityId !== 11) {
      return {
        icon: (
          <FontAwesomeIcon
            icon={faThumbsDown}
            style={{ color: colors.redAccent[400] }}
          />
        ),
        text: "Impact",
      };
    } else {
      return {
        icon: (
          <FontAwesomeIcon
            icon={faThumbsUp}
            style={{ color: colors.greenAccent[400] }}
          />
        ),
        text: "Offset",
      };
    }
  };

  return (
    <Box display="flex" width="100%" flexWrap="wrap" gap="10px">
      <Box flex="1 1 0" display="flex">
        <Box
          variant="outlined"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
          style={{ flex: 1, padding: "10px" }}
          backgroundColor={colors.grey[900]}
          borderRadius="5px"
        >
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            paddingBottom="10px"
          >
            <FindIcon
              colors={colors}
              utilityId={utilityId}
              utilitySubtypeId={utilitySubtypeId}
            />
          </Box>
          <Typography
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            fontWeight="600"
            fontSize=".9rem"
            textAlign="center"
            paddingBottom="5px"
          >
            {year}
            {parsedData.AdditionalMeterData[0].Units}
          </Typography>
          <Typography
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            fontWeight="600"
            fontSize=".9rem"
            textAlign="center"
          >
            Year to Date
          </Typography>
        </Box>
      </Box>

      <Box flex="1 1 0" display="flex">
        <Box
          variant="outlined"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
          style={{ flex: 1, padding: 0 }}
          backgroundColor={colors.grey[900]}
          height="100%"
          borderRadius="5px"
          overflow="hidden"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="calc(100% - 30px)"
            height="100%"
            padding="10px"
          >
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              paddingBottom="10px"
            >
              <FindIcon
                colors={colors}
                utilityId={utilityId}
                utilitySubtypeId={utilitySubtypeId}
              />
            </Box>
            <Typography
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              fontWeight="600"
              fontSize=".9rem"
              textAlign="center"
              paddingBottom="5px"
            >
              {month}
              {parsedData.AdditionalMeterData[0].Units}
            </Typography>
          </Box>
          <Box width="15px" height="100%">
            <DiffIndicator
              units={units}
              utilityId={utilityId}
              utilitySubtypeId={utilitySubtypeId}
              colors={colors}
              month={month}
              year={year}
            />
          </Box>
          <Typography
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              fontWeight="600"
              fontSize=".9rem"
              textAlign="center"
            >
              Month to Date
            </Typography>
        </Box>
      </Box>

      {(utilityId === 1 ||
        utilityId === 11 ||
        utilitySubtypeId === 3 ||
        utilitySubtypeId === 10 ||
        utilitySubtypeId === 11) && (
        <>
          <Box flex="1 1 0" display="flex">
            <Box
              variant="outlined"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
              style={{ flex: 1, padding: "10px" }}
              backgroundColor={colors.grey[900]}
              borderRadius="5px"
            >
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                paddingBottom="10px"
              >
                {findUtilityResources().icon}
              </Box>
              <Typography
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                fontWeight="600"
                fontSize=".9rem"
                textAlign="center"
                paddingBottom="5px"
              >
                {yearCarbon}
              </Typography>
              <Typography
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                fontWeight="600"
                fontSize=".9rem"
                textAlign="center"
              >
                CO2 {findUtilityResources().text}
              </Typography>
              <Typography
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                fontWeight="600"
                fontSize=".9rem"
                textAlign="center"
              >
                Year to Date
              </Typography>
            </Box>
          </Box>

          <Box flex="1 1 0" display="flex">
            <Box
              variant="outlined"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
              style={{ flex: 1, padding: 0 }}
              backgroundColor={colors.grey[900]}
              height="100%"
              borderRadius="5px"
              overflow="hidden"
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                width="calc(100% - 30px)"
                height="100%"
                padding="10px"
              >
                <Box>
                  <Box
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    paddingBottom="10px"
                  >
                    {findUtilityResources().icon}
                  </Box>
                  <Typography
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    fontWeight="600"
                    fontSize=".9rem"
                    textAlign="center"
                    paddingBottom="5px"
                  >
                    {monthCarbon}
                  </Typography>
                  <Typography
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    fontWeight="600"
                    fontSize=".9rem"
                    textAlign="center"
                  >
                    CO2 {findUtilityResources().text}
                  </Typography>
                </Box>
              </Box>

              <Box width="15px" height="100%">
                <DiffIndicator
                  units="CO2"
                  utilityId={utilityId}
                  utilitySubtypeId={utilitySubtypeId}
                  colors={colors}
                  month={monthCarbon}
                  year={yearCarbon}
                />
              </Box>

              <Typography
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                fontWeight="600"
                fontSize=".9rem"
                textAlign="center"
              >
                Month to Date
              </Typography>
            </Box>
          </Box>
        </>
      )}

      <Box flex="1 1 0" display="flex">
        <Box
          variant="outlined"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
          style={{ flex: 1, padding: "10px" }}
          backgroundColor={colors.grey[900]}
          borderRadius="5px"
        >
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            paddingBottom="10px"
          >
            <FontAwesomeIcon icon={faSatelliteDish} />
          </Box>
          <Typography
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            fontWeight="600"
            fontSize=".9rem"
            textAlign="center"
            paddingBottom="5px"
          >
            {Math.round(parsedData.AdditionalMeterData[0].DataIntegrity)}%
          </Typography>
          <Typography
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            fontWeight="600"
            fontSize=".9rem"
            textAlign="center"
          >
            Data Integrity
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default AdditionalMeterDetails;
