import { fetchAuthSession } from 'aws-amplify/auth';
import notification from '../components/Global/Toast';

const Loggers = {
  getLoggerData: async () => {
    console.log("Fetching logger Data...");
    try {
      const session = await fetchAuthSession();
      const token = session.tokens.accessToken || session.tokens.idToken;
  
      const url = 'https://www.data.mygreenvision.net/getloggers/';
  
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      });
  
      const data = await response.json();
  
      if (!response.ok || data.statusCode !== 200) {
        notification('error', "Failed to fetch logger data");
        throw new Error(`Code: ${data.statusCode}`);
      }
     
      console.log(JSON.parse(data.body));
      //notification('success', 'logger data fetched successfully');
      return data.body ? JSON.parse(data.body) : [];
  
    } catch (error) {
      console.error('Error fetching logger data:', error);
      notification('error', 'Failed to fetch logger data');
      throw error;
    }
  }  
};

export default Loggers;
