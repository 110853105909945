import { fetchAuthSession } from 'aws-amplify/auth';
import notification from '../components/Global/Toast';

const EditLocationGroups = {
  editGroup: async (locationGroupId, altText, lat, lng) => {
    console.log("Editing Location Group...");
    try {
      const session = await fetchAuthSession();
      const token = session.tokens.accessToken || session.tokens.idToken;

      const url = 'https://www.data.mygreenvision.net/EditLocationGroup/';

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          locationGroupId: locationGroupId,
          altText: altText,
          lat: lat,
          lng: lng
        }),
      });

      const data = await response.json();

      if (!response.ok || data.statusCode !== 200) {
        notification('error', "Failed to edit location");
        throw new Error("Code: ", data.statusCode);
      }
     
      console.log(data);
      notification('success', 'Location group edited successfully');
      return data;

    } catch (error) {
      console.error('Error editing location group:', error);
      notification('error', 'Failed to edit location');
      throw error;
    }
  }
};

export default EditLocationGroups;
