import { fetchAuthSession } from 'aws-amplify/auth';
import notification from '../components/Global/Toast';

const modifySubUser = async (editedSubUser) => {
  try {
    const session = await fetchAuthSession();
    const token = session.tokens.accessToken || session.tokens.idToken;

    console.log("modifySubUser.js Username: ", editedSubUser.username)
    const payload = {
      Username: editedSubUser.username, // old username
      NewUsername: editedSubUser.newUsername, // new username
      email: editedSubUser.email,
      password: editedSubUser.password,
      permission: editedSubUser.permission,
    };

    console.log(JSON.stringify(payload));

    const response = await fetch("https://www.data.mygreenvision.net/SubUser/modify", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(`Code: ${data.statusCode}`);
    }

    notification("success", "Sub-user modified successfully");
    return data;
  } catch (error) {
    console.error("Error modifying sub-user:", error);
    notification("error", "Failed to modify sub-user");
    throw error;
  }
};

export default modifySubUser;

