import React, { useState, useEffect } from "react";
import {
  Box,
  Tabs,
  Tab,
  Typography,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
} from "@mui/material";
import Header from "../../components/Global/Header";
import SearchIcon from "@mui/icons-material/Search";
import Spinner from "../../components/Global/Spinner";
import CustomTablePagination from "../../components/Global/CustomTablePagination";
import Loggers from "../../data/loggersQuery";
import Users from "../../data/usersQuery"; // Import users query
import SettingsRemoteTwoToneIcon from "@mui/icons-material/SettingsRemoteTwoTone";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";

const Admin = ({ colors }) => {
  const [loading, setLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [loggers, setLoggers] = useState([]);
  const [users, setUsers] = useState([]); // State for users
  const [searchQuery, setSearchQuery] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("SerialNumber");
  const [isSortingActive, setIsSortingActive] = useState(false); // State to check if sorting is active
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleRequestSort = (property) => {
    if (orderBy === property) {
      if (order === "asc") {
        setOrder("desc");
      } else if (order === "desc") {
        setOrder("asc");
        setOrderBy("");
        setIsSortingActive(false); // Set sorting active state to false when resetting sort
      }
    } else {
      setOrder("asc");
      setOrderBy(property);
      setIsSortingActive(true); // Set sorting active state to true
    }
  };

  const compare = (a, b, isAsc, property) => {
    const isString =
      typeof a[property] === "string" && typeof b[property] === "string";
    const valA = isString ? a[property].toLowerCase() : a[property];
    const valB = isString ? b[property].toLowerCase() : b[property];

    if (property === "IsLive") {
      return isAsc ? a.IsLive - b.IsLive : b.IsLive - a.IsLive;
    } else if (
      property === "InstallationDate" ||
      property === "LastMessageTime" ||
      property === "LastLogin"
    ) {
      return isAsc
        ? new Date(valA) - new Date(valB)
        : new Date(valB) - new Date(valA);
    } else {
      if (valA < valB) return isAsc ? -1 : 1;
      if (valA > valB) return isAsc ? 1 : -1;
      return 0;
    }
  };

  const getSortDirection = (column) => {
    if (orderBy === column) {
      return order;
    }
    return "asc"; // Default direction to "asc"
  };

  const filteredLoggers = loggers.filter(
    (logger) =>
      (logger.SerialNumber &&
        logger.SerialNumber.toLowerCase().includes(
          searchQuery.toLowerCase()
        )) ||
      (logger.AltText &&
        logger.AltText.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const filteredUsers = users.filter(
    (user) =>
      user.UserName &&
      user.UserName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const sortedLoggers = !isSortingActive
    ? filteredLoggers
    : filteredLoggers.sort((a, b) => compare(a, b, order === "asc", orderBy));
  const sortedUsers = !isSortingActive
    ? filteredUsers
    : filteredUsers.sort((a, b) => compare(a, b, order === "asc", orderBy));

  const displayedLoggers = sortedLoggers.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const displayedUsers = sortedUsers.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const fetchLoggerData = async () => {
      setLoading(true);
      try {
        const data = await Loggers.getLoggerData();
        setLoggers(data.Loggers || []);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    const fetchUserData = async () => {
      setLoading(true);
      try {
        const data = await Users.getUserData();
        setUsers(data.Users || []);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (tabIndex === 0) {
      fetchLoggerData();
    } else {
      fetchUserData();
    }
  }, [tabIndex]);

  return (
    <Box m="20px" width="100%" height="80%">
      <Header
        title="Admin"
        subtitle="View or Configure Accounts, Meters, Loggers... the Lot!"
        colors={colors}
      />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        backgroundColor= {colors.greenAccent[400]}
        borderRadius= "5px 5px 0 0"
      >
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          size="small"
          sx={{
            "& .Mui-selected *": { color: "white!important" },
            "& .MuiTabs-indicator": { backgroundColor: "white" },
          }}
        >
          <Tab icon={<SettingsRemoteTwoToneIcon />} label="Loggers" />
          <Tab icon={<AccountCircleTwoToneIcon />} label="Users" />
        </Tabs>
        <Box
            backgroundColor={colors.grey[900]}
            borderRadius="5px"
            overflow="hidden"
            height="20px"
            display="flex"
            alignItems="center"
            pl="5px"
            mr="15px"
          >
            <TextField
              variant="standard"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
      </Box>
      

      {loading ? (
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner colors={colors} />
        </Box>
      ) : (
        <Box display="flex" width="100%" height="100%">
          <Box
            width="100%"
            height="100%"
            overflow="hidden"
            sx={{ borderRadius: "5px" }}
            backgroundColor={colors.grey[900]}
          >
            <Box display="flex" flexDirection="column" height="95%">
              <TableContainer
                component={Paper}
                style={{
                  flex: 1,
                  overflowY: "auto",
                }}
              >
                {tabIndex === 0 ? (
                  displayedLoggers.length > 0 ? (
                    <Table size="small" stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <TableSortLabel
                              active={orderBy === "SerialNumber"}
                              direction={getSortDirection("SerialNumber")}
                              onClick={() => handleRequestSort("SerialNumber")}
                            >
                              Serial Number
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={orderBy === "AltText"}
                              direction={getSortDirection("AltText")}
                              onClick={() => handleRequestSort("AltText")}
                            >
                              Name
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={orderBy === "InstallationDate"}
                              direction={getSortDirection("InstallationDate")}
                              onClick={() =>
                                handleRequestSort("InstallationDate")
                              }
                            >
                              Installation Date
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={orderBy === "LastMessageTime"}
                              direction={getSortDirection("LastMessageTime")}
                              onClick={() =>
                                handleRequestSort("LastMessageTime")
                              }
                            >
                              Last Message Time
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={orderBy === "IsLive"}
                              direction={getSortDirection("IsLive")}
                              onClick={() => handleRequestSort("IsLive")}
                            >
                              Live
                            </TableSortLabel>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {displayedLoggers.map((logger) => (
                          <TableRow
                            key={logger.LoggerID}
                            onMouseEnter={() => {}}
                            onMouseLeave={() => {}}
                            sx={{
                              "&:hover": {
                                backgroundColor: colors.grey[700],
                                cursor: "pointer",
                              },
                            }}
                          >
                            <TableCell>{logger.SerialNumber}</TableCell>
                            <TableCell>{logger.AltText}</TableCell>
                            <TableCell>{logger.InstallationDate}</TableCell>
                            <TableCell>{logger.LastMessageTime}</TableCell>
                            <TableCell
                              sx={{
                                backgroundColor:
                                  logger.IsLive === 0
                                    ? colors.redAccent[300]
                                    : "#80de78",
                              }}
                            >
                              {logger.IsLive === 0 ? "No" : "Yes"}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  ) : (
                    <Box
                      width="100%"
                      height="100%"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography>No Loggers Found</Typography>
                    </Box>
                  )
                ) : displayedUsers.length > 0 ? (
                  <Table size="small" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <TableSortLabel
                            active={orderBy === "UserName"}
                            direction={getSortDirection("UserName")}
                            onClick={() => handleRequestSort("UserName")}
                          >
                            Username
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            active={orderBy === "LastLogin"}
                            direction={getSortDirection("LastLogin")}
                            onClick={() => handleRequestSort("LastLogin")}
                          >
                            Last Login
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            active={orderBy === "UserPermissionID"}
                            direction={getSortDirection("UserPermissionID")}
                            onClick={() =>
                              handleRequestSort("UserPermissionID")
                            }
                          >
                            User Permission
                          </TableSortLabel>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {displayedUsers.map((user) => (
                        <TableRow
                          key={user.UserID}
                          onMouseEnter={() => {}}
                          onMouseLeave={() => {}}
                          sx={{
                            "&:hover": {
                              backgroundColor: colors.grey[700],
                              cursor: "pointer",
                            },
                          }}
                        >
                          <TableCell>{user.UserName}</TableCell>
                          <TableCell>{user.LastLogin}</TableCell>
                          <TableCell>{user.UserPermissionID}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <Box
                    width="100%"
                    height="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography>No Users Found</Typography>
                  </Box>
                )}
              </TableContainer>
              {(displayedLoggers.length > 0 || displayedUsers.length > 0) && (
                <Box
                  sx={{
                    position: "sticky",
                    bottom: 0,
                    backgroundColor: colors.grey[800],
                  }}
                >
                  <CustomTablePagination
                    count={
                      tabIndex === 0
                        ? filteredLoggers.length
                        : filteredUsers.length
                    }
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    colors={colors}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Admin;
