import { fetchAuthSession } from 'aws-amplify/auth';

const LocationGroups = {
  fetchGroups: async () => {
    console.log("Fetching Location Groups...");
    try {
      const session = await fetchAuthSession();
      const token = session.tokens.accessToken || session.tokens.idToken;

      const url = `https://www.data.mygreenvision.net/locationgroup`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data);
      return data; // Ensure data is returned

    } catch (error) {
      console.error('Error fetching location groups:', error);
      throw error; // Rethrow the error to be handled by the caller
    }
  }
};

export default LocationGroups;