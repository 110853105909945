import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from "react";
import {
  Box,
  useTheme,
  Button,
  Modal,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  Card,
  FormControl,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Header from "../../components/Global/Header";
import RoomIcon from "@mui/icons-material/Room";
import { GoogleMap, Marker, OverlayView } from "@react-google-maps/api";
import GoogleMapsAutocomplete from "../../components/Map/GoogleMapsAutocomplete";
import { useGoogleMapsLoader } from "../../googleMapsLoader";
import LocationGroups from "../../data/locationGroupsQuery";
import SearchIcon from "@mui/icons-material/Search";
import AddLocationGroups from "../../data/addLocationGroup";
import EditLocationGroups from "../../data/editLocationGroup";
import DeleteLocationGroups from "../../data/deleteLocationGroup";
import ManageLocationRoots from "../../data/manageLocationRoots";
import CustomTablePagination from "../../components/Global/CustomTablePagination";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Spinner from "../../components/Global/Spinner";

const Map = ({ colors }) => {
  const theme = useTheme();
  const mapRef = useRef(null);
  const modalMapRef = useRef(null);
  const editModalMapRef = useRef(null);
  const { isLoaded } = useGoogleMapsLoader();
  const [mapCenter, setMapCenter] = useState({ lat: 53.3811, lng: -1.4701 });
  const [zoomLevel, setZoomLevel] = useState(7);
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [open, setOpen] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [dropdownValue, setDropdownValue] = useState({});
  const [meterTreeRows, setMeterTreeRows] = useState([]);
  const [locationGroups, setLocationGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalMapCenter, setModalMapCenter] = useState({
    lat: 53.3811,
    lng: -1.4701,
  });
  const [modalZoomLevel, setModalZoomLevel] = useState(7);
  const [hoveredMarker, setHoveredMarker] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [secondTablePage, setSecondTablePage] = useState(0);
  const [secondTableRowsPerPage, setSecondTableRowsPerPage] = useState(10);
  const [secondTableSearchQuery, setSecondTableSearchQuery] = useState("");
  const [locationGroupChildCounts, setLocationGroupChildCounts] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState("");
  const [deleteLocationGroupID, setDeleteLocationGroupID] = useState(null);
  const [editModalMapCenter, setEditModalMapCenter] = useState({
    lat: 53.3811,
    lng: -1.4701,
  });
  const [dropdownChanges, setDropdownChanges] = useState([]);
  const [meterTreeSortConfig, setMeterTreeSortConfig] = useState({
    key: "",
    direction: "asc",
  });
  const [locationGroupSortConfig, setLocationGroupSortConfig] = useState({
    key: "",
    direction: "asc",
  });
  const [changesSaved, setChangesSaved] = useState(true);

  const handleEditModalOpen = (group) => {
    setSelectedGroup(group);
    setEditModalMapCenter({ lat: group.Lat, lng: group.Lng });
    setEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setEditModalOpen(false);
    setSelectedGroup(null);
  };

  const handleDeleteModalOpen = (group) => {
    setSelectedGroup(group);
    setDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
    setSelectedGroup(null);
    setDeleteConfirmation("");
  };

  const locationGroupChildCountColumns = [
    { id: "AltText", label: "Location Name" },
    { id: "ChildCount", label: "Children" },
    { id: "Lat", label: "Lat" },
    { id: "Lng", label: "Lng" },
    { id: "edit", label: "Edit" },
    { id: "delete", label: "Delete" },
  ];

  const calculateCenterAndZoom = (locations) => {
    if (locations.length === 0) return;

    let validLocations = locations.filter(
      (location) =>
        location.Lat !== undefined &&
        location.Lng !== undefined &&
        !isNaN(location.Lat) &&
        !isNaN(location.Lng)
    );

    if (validLocations.length === 0) {
      console.error("No valid locations to calculate center and zoom");
      return;
    }

    let latSum = 0;
    let lngSum = 0;
    let minLat = Infinity;
    let maxLat = -Infinity;
    let minLng = Infinity;
    let maxLng = -Infinity;

    validLocations.forEach((location) => {
      latSum += location.Lat;
      lngSum += location.Lng;
      if (location.Lat < minLat) minLat = location.Lat;
      if (location.Lat > maxLat) maxLat = location.Lat;
      if (location.Lng < minLng) minLng = location.Lng;
      if (location.Lng > maxLng) maxLng = location.Lng;
    });

    const avgLat = latSum / validLocations.length;
    const avgLng = lngSum / validLocations.length;

    if (!isFinite(avgLat) || !isFinite(avgLng)) {
      console.error("Invalid average latitude or longitude");
      return;
    }

    const latDiff = maxLat - minLat;
    const lngDiff = maxLng - minLng;
    const maxDiff = Math.max(latDiff, lngDiff);

    let zoom;
    if (maxDiff < 0.01) {
      zoom = 16;
    } else if (maxDiff < 0.1) {
      zoom = 12;
    } else if (maxDiff < 0.5) {
      zoom = 9;
    } else if (maxDiff < 1) {
      zoom = 8;
    } else if (maxDiff < 10) {
      zoom = 7;
    } else {
      zoom = 3;
    }

    setMapCenter({ lat: avgLat, lng: avgLng });
    setZoomLevel(zoom);
  };

  const getSortIcon = (columnId, sortConfig) => {
    if (sortConfig.key !== columnId) {
      return <KeyboardArrowRightIcon />;
    }
    if (sortConfig.direction === "asc") {
      return <KeyboardArrowUpIcon />;
    }
    if (sortConfig.direction === "desc") {
      return <KeyboardArrowDownIcon />;
    }
    return <KeyboardArrowRightIcon />;
  };

  const fetchData = async (refreshFirstTable = true) => {
    setLoading(true);
    try {
      const response = await LocationGroups.fetchGroups();
      const data = JSON.parse(response.body);
      const locationGroupData = data.LocationGroupData || [];
      const locationGroupChildCount = Array.isArray(
        data.LocationGroupChildCount
      )
        ? data.LocationGroupChildCount
        : [];

      if (refreshFirstTable) {
        setMeterTreeRows(locationGroupData);
      }
      setLocationGroupChildCounts(locationGroupChildCount); // Set state for the second table

      const uniqueGroups = [];
      const uniqueGroupIds = new Set();

      locationGroupChildCount.forEach((item) => {
        if (
          item.LocationGroupID &&
          item.AltText &&
          !uniqueGroupIds.has(item.LocationGroupID)
        ) {
          uniqueGroupIds.add(item.LocationGroupID);
          uniqueGroups.push({
            id: item.LocationGroupID,
            altText: item.AltText,
            ChildCount: item.ChildCount,
            lat: item.Lat,
            lng: item.Lng,
          });
        }
      });

      uniqueGroups.sort((a, b) => a.altText.localeCompare(b.altText));
      setLocationGroups(uniqueGroups);

      calculateCenterAndZoom(uniqueGroups);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching location groups:", error);
    }
  };

  useEffect(() => {
    fetchData(); // Always fetch data on component mount
  }, [refreshData]);

  useEffect(() => {
    if (editModalMapRef.current && selectedGroup) {
      editModalMapRef.current.setCenter({
        lat: selectedGroup.Lat,
        lng: selectedGroup.Lng,
      });
    }
  }, [selectedGroup]);

  const onLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleMarkerMouseOver = (marker) => {
    setHoveredMarker(marker);
  };

  const handleMarkerMouseOut = () => {
    setHoveredMarker(null);
  };

  const handleMarkerClick = (group) => {
    setSelectedGroup(group);
    setEditModalOpen(true);
  };

  const renderTooltip = () => {
    if (hoveredMarker) {
      const tooltipBackgroundColor =
        theme.palette.mode === "light"
          ? colors.primary[400]
          : colors.primary[900];
      const tooltipTextColor = colors.primary[100];

      const tooltipStyle = {
        position: "absolute",
        backgroundColor: tooltipBackgroundColor,
        color: tooltipTextColor,
        padding: "10px",
        borderRadius: "8px",
        boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
        pointerEvents: "none",
        transform: "translate(-50%, -150%)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        position: "relative",
        minWidth: "max-content",
        maxWidth: "300px",
        whiteSpace: "nowrap",
      };

      const tooltipPointerStyle = {
        content: '""',
        position: "absolute",
        bottom: "-18px",
        left: "50%",
        transform: "translateX(-50%)",
        borderWidth: "10px",
        borderStyle: "solid",
        borderColor: tooltipBackgroundColor.concat(
          " transparent transparent transparent"
        ),
      };

      return (
        <OverlayView
          position={{ lat: hoveredMarker.lat, lng: hoveredMarker.lng }}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <div style={tooltipStyle}>
            <Typography fontWeight="800" variant="subtitle2">
              {hoveredMarker.altText || "Unknown"}
            </Typography>
            <Box display="flex">
              <Typography variant="body2">Meters: </Typography>
              <Typography
                variant="body2"
                style={{ whiteSpace: "nowrap", marginLeft: "4px" }}
              >
                {hoveredMarker.ChildCount || "Unknown"}
              </Typography>
            </Box>
            <div style={tooltipPointerStyle} />
          </div>
        </OverlayView>
      );
    }
    return null;
  };

  const handlePlaceSelect = (place) => {
    if (place.lat && place.lng) {
      setModalMapCenter({ lat: place.lat, lng: place.lng });
      setLat(place.lat);
      setLng(place.lng);
      if (modalMapRef.current) {
        modalMapRef.current.setCenter({ lat: place.lat, lng: place.lng });
        modalMapRef.current.setZoom(16);
      }
    }
  };

  const handleEditPlaceSelect = (place) => {
    if (place.lat && place.lng) {
      setSelectedGroup((prev) => ({
        ...prev,
        Lat: place.lat,
        Lng: place.lng,
      }));
      if (editModalMapRef.current) {
        editModalMapRef.current.setCenter({ lat: place.lat, lng: place.lng });
        editModalMapRef.current.setZoom(16);
      }
    }
  };

  const handleRowMouseOver = (row) => {
    const group = locationGroups.find(
      (group) => group.id === row.LocationGroupID
    );
    if (group) {
      setHoveredMarker({
        ...group,
        ChildCount: row.ChildCount || group.ChildCount,
      });
    } else {
      setHoveredMarker(null);
    }
  };

  const handleRowMouseOut = () => {
    setHoveredMarker(null);
  };

  const handleLatChange = (event) => {
    const newLat = parseFloat(event.target.value);
    setLat(newLat);
    setModalMapCenter((prevCenter) => ({ ...prevCenter, lat: newLat }));
    modalMapRef.current.setCenter({ lat: newLat, lng });
  };

  const handleLngChange = (event) => {
    const newLng = parseFloat(event.target.value);
    setLng(newLng);
    setModalMapCenter((prevCenter) => ({ ...prevCenter, lng: newLng }));
    modalMapRef.current.setCenter({ lat, lng: newLng });
  };

  const handleEditLatChange = (event) => {
    const newLat = parseFloat(event.target.value);
    setSelectedGroup((prev) => ({ ...prev, Lat: newLat }));
    editModalMapRef.current.setCenter({
      lat: newLat,
      lng: selectedGroup.Lng,
    });
  };

  const handleEditLngChange = (event) => {
    const newLng = parseFloat(event.target.value);
    setSelectedGroup((prev) => ({ ...prev, Lng: newLng }));
    editModalMapRef.current.setCenter({
      lat: selectedGroup.Lat,
      lng: newLng,
    });
  };

  const handleDropdownChange = (rowId, value) => {
    setDropdownValue((prev) => ({ ...prev, [rowId]: value }));

    setDropdownChanges((prevChanges) => {
      const existingChangeIndex = prevChanges.findIndex(
        (change) => change.meterTreeID === rowId
      );

      if (value === "unassigned") value = null;

      // Find the original value for this row from meterTreeRows
      const originalValue =
        meterTreeRows.find((row) => row.MeterTreeID === rowId)
          ?.LocationGroupID || null;

      if (value === originalValue) {
        // If the new value matches the original value, remove the change
        if (existingChangeIndex >= 0) {
          const updatedChanges = [...prevChanges];
          updatedChanges.splice(existingChangeIndex, 1);
          return updatedChanges;
        }
        return prevChanges;
      } else {
        const newChange = { meterTreeID: rowId, LocationGroupID: value };
        if (existingChangeIndex >= 0) {
          const updatedChanges = [...prevChanges];
          updatedChanges[existingChangeIndex] = newChange;
          return updatedChanges;
        } else {
          return [...prevChanges, newChange];
        }
      }
    });

    setChangesSaved(false);
  };

  const meterTreeColumns = [
    { id: "MeterName", label: "Meter Name" },
    { id: "FriendlyName", label: "Site" },
    { id: "dropdown", label: "Location Group" },
  ];

  const handleMeterTreeSort = (columnId) => {
    setMeterTreeSortConfig((prevConfig) => {
      if (prevConfig.key === columnId) {
        if (prevConfig.direction === "asc") {
          return { key: columnId, direction: "desc" };
        } else if (prevConfig.direction === "desc") {
          return { key: "", direction: "asc" }; // Default state
        }
      }
      return { key: columnId, direction: "asc" };
    });
  };

  const handleLocationGroupSort = (columnId) => {
    setLocationGroupSortConfig((prevConfig) => {
      if (prevConfig.key === columnId) {
        if (prevConfig.direction === "asc") {
          return { key: columnId, direction: "desc" };
        } else if (prevConfig.direction === "desc") {
          return { key: "", direction: "asc" }; // Default state
        }
      }
      return { key: columnId, direction: "asc" };
    });
  };

  const sortedMeterTreeRows = useMemo(() => {
    if (meterTreeSortConfig.key && meterTreeSortConfig.key !== "dropdown") {
      const sortedRows = [...meterTreeRows].sort((a, b) => {
        let aValue = a[meterTreeSortConfig.key];
        let bValue = b[meterTreeSortConfig.key];

        if (aValue < bValue) {
          return meterTreeSortConfig.direction === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return meterTreeSortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
      return sortedRows;
    }

    if (meterTreeSortConfig.key === "dropdown" && changesSaved) {
      const sortedRows = [...meterTreeRows].sort((a, b) => {
        let aValue = dropdownValue[a.MeterTreeID] || a.LocationGroupID;
        let bValue = dropdownValue[b.MeterTreeID] || b.LocationGroupID;

        if (aValue < bValue) {
          return meterTreeSortConfig.direction === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return meterTreeSortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
      return sortedRows;
    }

    return meterTreeRows;
  }, [meterTreeRows, meterTreeSortConfig, dropdownValue, changesSaved]);

  const handleSaveChanges = async () => {
    try {
      const result = await ManageLocationRoots.manageRoots(dropdownChanges);
      console.log("Location roots save:", result);
      setDropdownChanges([]); // Clear dropdown changes after saving
      setRefreshData(!refreshData); // Trigger data refresh
      setChangesSaved(true); // Mark changes as saved
    } catch (error) {
      console.error("Error saving location roots:", error);
    }
  };

  const sortedLocationGroupRows = useMemo(() => {
    if (locationGroupSortConfig.key) {
      const sortedRows = [...locationGroupChildCounts].sort((a, b) => {
        let aValue = a[locationGroupSortConfig.key];
        let bValue = b[locationGroupSortConfig.key];

        if (aValue < bValue) {
          return locationGroupSortConfig.direction === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return locationGroupSortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
      return sortedRows;
    }
    return locationGroupChildCounts;
  }, [locationGroupChildCounts, locationGroupSortConfig]);

  const filteredRows = useMemo(() => {
    return sortedMeterTreeRows.filter(
      (row) =>
        row.MeterName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.FriendlyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (row.LocationGroupAltText &&
          row.LocationGroupAltText.toLowerCase().includes(
            searchQuery.toLowerCase()
          ))
    );
  }, [sortedMeterTreeRows, searchQuery]);

  const filteredSecondTableRows = useMemo(() => {
    return sortedLocationGroupRows.filter(
      (row) =>
        row.AltText.toLowerCase().includes(
          secondTableSearchQuery.toLowerCase()
        ) ||
        row.ChildCount.toString().includes(
          secondTableSearchQuery.toLowerCase()
        ) ||
        row.Lat.toString().includes(secondTableSearchQuery.toLowerCase()) ||
        row.Lng.toString().includes(secondTableSearchQuery.toLowerCase())
    );
  }, [sortedLocationGroupRows, secondTableSearchQuery]);

  const paginatedSecondTableRows = useMemo(() => {
    return filteredSecondTableRows.slice(
      secondTablePage * secondTableRowsPerPage,
      secondTablePage * secondTableRowsPerPage + secondTableRowsPerPage
    );
  }, [filteredSecondTableRows, secondTablePage, secondTableRowsPerPage]);

  const handleModalMapCenterChange = useCallback(() => {
    if (modalMapRef.current) {
      const newCenter = modalMapRef.current.getCenter();
      const newLat = newCenter.lat();
      const newLng = newCenter.lng();
      setModalMapCenter((prevCenter) => {
        if (prevCenter.lat !== newLat || prevCenter.lng !== newLng) {
          setLat(newLat);
          setLng(newLng);
          return { lat: newLat, lng: newLng };
        }
        return prevCenter;
      });
    }
  }, []);

  const handleEditModalMapCenterChange = useCallback(() => {
    if (editModalMapRef.current) {
      const newCenter = editModalMapRef.current.getCenter();
      const newLat = newCenter.lat();
      const newLng = newCenter.lng();
      setSelectedGroup((prev) => ({
        ...prev,
        Lat: newLat,
        Lng: newLng,
      }));
    }
  }, []);

  const handleConfirm = async () => {
    try {
      const result = await AddLocationGroups.addGroup(groupName, lat, lng);
      console.log("Location group added:", result);
      setRefreshData(!refreshData); // Trigger data refresh
      handleClose();
    } catch (error) {
      console.error("Error adding location group:", error);
    }
  };

  const resetChanges = () => {
    setDropdownChanges([]);
    setDropdownValue({});
  };

  const handleDeleteLocationGroup = async () => {
    try {
      const result = await DeleteLocationGroups.deleteGroup(
        selectedGroup.LocationGroupID
      );
      console.log("Location group delete:", result);
      setRefreshData(!refreshData); // Trigger data refresh
      handleClose();
      setDeleteLocationGroupID(null);
    } catch (error) {
      console.error("Error deleting location group:", error);
    }
  };

  const handleEditLocationGroup = async () => {
    try {
      const result = await EditLocationGroups.editGroup(
        selectedGroup.LocationGroupID,
        selectedGroup.AltText,
        selectedGroup.Lat,
        selectedGroup.Lng
      );
      console.log("Location group edited:", result);
      setRefreshData(!refreshData); // Trigger data refresh
      handleEditModalClose();
    } catch (error) {
      console.error("Error editing location group:", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSecondTableChangePage = (event, newPage) => {
    setSecondTablePage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSecondTableChangeRowsPerPage = (event) => {
    setSecondTableRowsPerPage(parseInt(event.target.value, 10));
    setSecondTablePage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0); // Reset page to 0 when search query changes
  };

  const handleSecondTableSearchChange = (event) => {
    setSecondTableSearchQuery(event.target.value);
    setSecondTablePage(0); // Reset page to 0 when search query changes
  };

  const [speedDialOpen, setSpeedDialOpen] = useState(false);
  const handleSpeedDialOpen = () => setSpeedDialOpen(true);
  const handleSpeedDialClose = () => setSpeedDialOpen(false);

  const hasGroupDetailsChanged = useMemo(() => {
    if (!selectedGroup) return false;
    const initialGroup = locationGroups.find(
      (group) => group.id === selectedGroup.LocationGroupID
    );
    if (!initialGroup) return false;

    return (
      selectedGroup.AltText !== initialGroup.altText ||
      selectedGroup.Lat !== initialGroup.lat ||
      selectedGroup.Lng !== initialGroup.lng
    );
  }, [selectedGroup, locationGroups]);

  return (
    <Box m="20px" width="100%">
      <Header title="Map" subtitle="View Meters on the Map" colors={colors} />
      <Box
        display="flex"
        justifyContent="space-between"
        height="80vh"
        width="100%"
      >
        {loading ? (
          <Box
            width="65%"
            height="100%"
            overflow="visible"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Spinner colors={colors} />
          </Box>
        ) : (
          <Box display="inline-block" width="65%" height="100%">
            <Card
              flex="1"
              p="10px"
              display="flex"
              flexDirection="column"
              height="48%"
              sx={{ boxShadow: 2 }}
            >
              <Box
                width="100%"
                height="5%"
                padding="8px"
                backgroundColor={colors.greenAccent[400]}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography sx={{ fontWeight: "800" }}>
                  Meter Locations
                </Typography>
                <Box
                  backgroundColor={colors.grey[900]}
                  borderRadius="5px"
                  overflow="hidden"
                  height="20px"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  pl="5px"
                >
                  <TextField
                    variant="standard"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>
              <TableContainer
                style={{
                  flex: 1,
                  overflowY: "scroll",
                  maxHeight: "28vh",
                  minHeight: "28vh",
                  backgroundColor: colors.grey[900], // Added background color here
                }}
              >
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      {meterTreeColumns.map((column) => (
                        <TableCell
                          key={column.id}
                          sx={{ backgroundColor: colors.grey[800] }} // Changed background color here
                          onClick={() => handleMeterTreeSort(column.id)}
                          style={{
                            cursor:
                              column.id === "edit" || column.id === "delete"
                                ? "default"
                                : "pointer",
                          }}
                        >
                          <Box display="flex" alignItems="center">
                            {column.label}
                            {column.id !== "edit" &&
                              column.id !== "delete" &&
                              getSortIcon(column.id, meterTreeSortConfig)}
                          </Box>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {filteredRows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <TableRow
                          key={row.MeterTreeID}
                          onMouseOver={() => handleRowMouseOver(row)}
                          onMouseOut={handleRowMouseOut}
                          sx={{
                            "&:hover": {
                              backgroundColor: colors.grey[400],
                            },
                          }}
                        >
                          {meterTreeColumns.map((column) => (
                            <TableCell
                              key={column.id}
                              sx={{ backgroundColor: colors.grey[900] }}
                            >
                              {" "}
                              {column.id === "dropdown" ? (
                                <FormControl
                                  sx={{ m: 0, minWidth: 120 }}
                                  size="small"
                                >
                                  <Select
                                    sx={{
                                      padding: 0,
                                      height: "23px",
                                      backgroundColor: dropdownChanges.some(
                                        (change) =>
                                          change.meterTreeID === row.MeterTreeID
                                      )
                                        ? colors.redAccent[800]
                                        : "inherit",
                                    }}
                                    value={
                                      dropdownValue[row.MeterTreeID] ||
                                      row.LocationGroupID ||
                                      ""
                                    }
                                    onChange={(e) =>
                                      handleDropdownChange(
                                        row.MeterTreeID,
                                        e.target.value === "unassigned"
                                          ? null
                                          : e.target.value
                                      )
                                    }
                                    fullWidth
                                    displayEmpty
                                  >
                                    <MenuItem value={null}>Unassigned</MenuItem>
                                    {locationGroupChildCounts.map((group) => (
                                      <MenuItem
                                        sx={{ padding: 0 }}
                                        key={group.LocationGroupID}
                                        value={group.LocationGroupID}
                                      >
                                        {group.AltText}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              ) : (
                                row[column.id]
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box sx={{ backgroundColor: colors.grey[800] }}>
                <CustomTablePagination
                  count={filteredRows.length}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  actionTitle="Save"
                  handleOpen={handleOpen}
                  actionDisabled={dropdownChanges.length === 0}
                  secondaryActionDisabled={dropdownChanges.length === 0}
                  actionTheme="secondary"
                  secondaryActionTheme="primary"
                  onSaveClick={handleSaveChanges}
                  resetChanges={resetChanges}
                  secondaryActionTitle={"Reset"}
                />
              </Box>
            </Card>

            <Box mt="2%" />

            <Card
              flex="1"
              p="10px"
              display="flex"
              flexDirection="column"
              height="48%"
              backgroundColor={colors.grey[900]}
              sx={{ boxShadow: 2 }} 

            >
              <Box
                width="100%"
                height="5%"
                padding="8px"
                backgroundColor={colors.greenAccent[400]}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography sx={{ fontWeight: "800" }}>
                  Location Group
                </Typography>
                <Box
                  backgroundColor={colors.grey[900]}
                  borderRadius="5px"
                  overflow="hidden"
                  height="20px"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  pl="5px"
                >
                  <TextField
                    variant="standard"
                    placeholder="Search..."
                    value={secondTableSearchQuery}
                    onChange={handleSecondTableSearchChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>
              <TableContainer
                style={{
                  flex: 1,
                  overflowY: "scroll",
                  maxHeight: "28vh",
                  minHeight: "28vh",
                  backgroundColor: colors.grey[900], 
                }}
              >
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      {locationGroupChildCountColumns.map((column) => (
                        <TableCell
                          key={column.id}
                          onClick={() => handleLocationGroupSort(column.id)}
                          style={{
                            cursor:
                              column.id === "edit" || column.id === "delete"
                                ? "default"
                                : "pointer",
                            backgroundColor: colors.grey[800], // Changed background color here
                          }}
                        >
                          <Box display="flex" alignItems="center">
                            {column.label}
                            {column.id !== "edit" &&
                              column.id !== "delete" &&
                              getSortIcon(column.id, locationGroupSortConfig)}
                          </Box>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedSecondTableRows.length > 0 ? (
                      paginatedSecondTableRows.map((row) => (
                        <TableRow
                          key={row.LocationGroupID}
                          onMouseOver={() => handleRowMouseOver(row)}
                          onMouseOut={handleRowMouseOut}
                          sx={{
                            "&:hover": {
                              backgroundColor: colors.grey[400],
                            },
                          }}
                        >
                          {locationGroupChildCountColumns.map((column) => (
                            <TableCell
                              key={column.id}
                              sx={{ backgroundColor: colors.grey[900] }}
                            >
                              {" "}
                              {column.id === "edit" ? (
                                <IconButton
                                  sx={{
                                    padding: 0,
                                    "&:hover": {
                                      color: colors.greenAccent[600],
                                    },
                                  }}
                                  onClick={() => handleEditModalOpen(row)}
                                >
                                  <EditIcon />
                                </IconButton>
                              ) : column.id === "delete" ? (
                                <IconButton
                                  sx={{
                                    padding: 0,
                                    "&:hover": { color: "red" },
                                  }}
                                  onClick={() => handleDeleteModalOpen(row)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              ) : (
                                row[column.id]
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={locationGroupChildCountColumns.length}
                          align="center"
                        >
                          No Location Groups configured.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box sx={{ backgroundColor: colors.grey[800] }}>
                <CustomTablePagination
                  count={filteredSecondTableRows.length}
                  page={secondTablePage}
                  rowsPerPage={secondTableRowsPerPage}
                  onPageChange={handleSecondTableChangePage}
                  onRowsPerPageChange={handleSecondTableChangeRowsPerPage}
                  actionTitle="Add Location"
                  actionDisabled={false}
                  actionTheme="secondary"
                  handleOpen={handleOpen}
                  onSaveClick={handleSaveChanges}
                />
              </Box>
            </Card>
          </Box>
        )}

        <Box
          flex="1"
          ml="2%"
          height="100%"
          borderRadius="5px"
          minWidth="38%"
          maxWidth="38%"
          overflow="hidden"
        >
          <Box
            flex="1"
            height="100%"
            borderRadius="5px"
            width="100%"
            overflow="hidden"
          >
            {isLoaded ? (
              <GoogleMap
                mapContainerStyle={{ width: "100%", height: "100%" }}
                center={mapCenter}
                zoom={zoomLevel}
                options={{
                  mapTypeId: "hybrid",
                  disableDefaultUI: true,
                }}
                onLoad={onLoad}
              >
                {locationGroups.map((group) => (
                  <Marker
                    key={group.id}
                    position={{ lat: group.lat, lng: group.lng }}
                    onMouseOver={() => handleMarkerMouseOver(group)}
                    onMouseOut={handleMarkerMouseOut}
                    onClick={() => handleMarkerClick(group)}
                  />
                ))}
                {renderTooltip()}
              </GoogleMap>
            ) : (
              <></>
            )}
          </Box>
        </Box>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            position="absolute"
            top="50%"
            left="50%"
            style={{ transform: "translate(-50%, -50%)", zIndex: 1300 }}
            width="50vw"
            bgcolor="background.paper"
            boxShadow={24}
            p={4}
            borderRadius="5px"
          >
            <Box display="flex" width="100%">
              <Typography id="modal-title" variant="h4" component="h4">
                Create a location group
              </Typography>
            </Box>

            {
              //(dropdownChanges.length !== 0) &&
              //<Chip color="warning" icon={<ReportOutlinedIcon />} label="Warning: Any changes to meter locations will be lost if you proceed without saving."/>
            }

            <TextField
              width="100%"
              id="group-name"
              label="Location Group Name"
              variant="filled"
              fullWidth
              marginTop="32px"
              marginBottom="32px"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
            />
            <GoogleMapsAutocomplete
              onPlaceSelected={handlePlaceSelect}
              style={{ zIndex: 2000 }}
              marginTop="32px"
              marginBottom="32px"
            />
            {isLoaded ? (
              <Box
                position="relative"
                borderRadius="10px"
                width="100%"
                height="400px"
                overflow="hidden"
              >
                <Box
                  position="absolute"
                  top="46%"
                  left="50%"
                  transform="translate(-50%, -100%)"
                  zIndex="10"
                  style={{ pointerEvents: "none" }}
                >
                  <RoomIcon sx={{ transform: "scale(2.4)" }} color="error" />
                </Box>
                <GoogleMap
                  mapContainerStyle={{ width: "100%", height: "100%" }}
                  center={modalMapCenter}
                  zoom={modalZoomLevel}
                  options={{
                    mapTypeId: "hybrid",
                    disableDefaultUI: true,
                  }}
                  onLoad={(map) => {
                    modalMapRef.current = map;
                  }}
                  onDragEnd={handleModalMapCenterChange}
                  onZoomChanged={handleModalMapCenterChange}
                />
              </Box>
            ) : (
              <></>
            )}
            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              mt="10px"
            >
              <TextField
                label="Latitude"
                variant="outlined"
                fullWidth
                margin="normal"
                value={lat}
                onChange={handleLatChange}
                type="number"
                inputProps={{ step: "any" }}
              />
              <TextField
                label="Longitude"
                variant="outlined"
                fullWidth
                margin="normal"
                value={lng}
                onChange={handleLngChange}
                type="number"
                inputProps={{ step: "any" }}
              />
            </Box>

            <Box display="flex" width="100%" justifyContent="space-between">
              <Button
                onClick={handleClose}
                variant="contained"
                color="primary"
                margin="normal"
                pt="10px"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                margin="normal"
                pt="10px"
                onClick={handleConfirm}
                color="secondary"
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Modal>

        <Modal
          open={editModalOpen}
          onClose={handleEditModalClose}
          aria-labelledby="edit-modal-title"
          aria-describedby="edit-modal-description"
        >
          <Box
            position="absolute"
            top="50%"
            left="50%"
            style={{ transform: "translate(-50%, -50%)", zIndex: 1300 }}
            width="50vw"
            bgcolor="background.paper"
            boxShadow={24}
            p={4}
            borderRadius="5px"
          >
            <Typography id="edit-modal-title" variant="h4" component="h4">
              Edit Location Group
            </Typography>
            <Box display="flex" flexDirection="column">
              <TextField
                id="edit-group-name"
                label="Location Group Name"
                variant="filled"
                fullWidth
                margin="normal"
                value={selectedGroup ? selectedGroup.AltText : ""}
                onChange={(e) =>
                  setSelectedGroup({
                    ...selectedGroup,
                    AltText: e.target.value,
                  })
                }
              />
              <Box width="100%">
                <GoogleMapsAutocomplete
                  pt="32px"
                  pb="32px"
                  onPlaceSelected={handleEditPlaceSelect}
                  style={{ zIndex: 2000 }}
                />
                {isLoaded ? (
                  <Box
                    position="relative"
                    borderRadius="10px"
                    width="100%"
                    height="400px"
                    overflow="hidden"
                  >
                    <Box
                      position="absolute"
                      top="46%"
                      left="50%"
                      transform="translate(-50%, -100%)"
                      zIndex="10"
                      style={{ pointerEvents: "none" }}
                    >
                      <RoomIcon
                        sx={{ transform: "scale(2.4)" }}
                        color="error"
                      />
                    </Box>
                    <GoogleMap
                      mapContainerStyle={{ width: "100%", height: "100%" }}
                      center={{
                        lat: selectedGroup ? selectedGroup.Lat : 53.3811,
                        lng: selectedGroup ? selectedGroup.Lng : -1.4701,
                      }}
                      zoom={modalZoomLevel}
                      options={{
                        mapTypeId: "hybrid",
                        disableDefaultUI: true,
                      }}
                      onLoad={(map) => {
                        editModalMapRef.current = map;
                        if (selectedGroup) {
                          map.setCenter({
                            lat: selectedGroup.Lat,
                            lng: selectedGroup.Lng,
                          });
                        }
                      }}
                      onDragEnd={handleEditModalMapCenterChange}
                      onZoomChanged={handleEditModalMapCenterChange}
                    />
                  </Box>
                ) : (
                  <></>
                )}
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                  mt="10px"
                >
                  <TextField
                    label="Latitude"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={selectedGroup ? selectedGroup.Lat : ""}
                    onChange={handleEditLatChange}
                    type="number"
                    inputProps={{ step: "any" }}
                  />
                  <TextField
                    label="Longitude"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={selectedGroup ? selectedGroup.Lng : ""}
                    onChange={handleEditLngChange}
                    type="number"
                    inputProps={{ step: "any" }}
                  />
                </Box>
              </Box>
              <Box mt={2} display="flex" justifyContent="space-between">
                <Button variant="contained" onClick={handleEditModalClose}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={!hasGroupDetailsChanged}
                  onClick={handleEditLocationGroup}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>

        {/* Delete Modal */}
        <Modal
          open={deleteModalOpen}
          onClose={handleDeleteModalClose}
          aria-labelledby="delete-modal-title"
          aria-describedby="delete-modal-description"
        >
          <Box
            position="absolute"
            top="50%"
            left="50%"
            style={{ transform: "translate(-50%, -50%)", zIndex: 1300 }}
            width="30vw"
            bgcolor="background.paper"
            boxShadow={24}
            p={4}
            borderRadius="5px"
          >
            <Typography id="delete-modal-title" variant="h4" component="h4">
              Confirm Deletion
            </Typography>
            <Box display="flex" alignItems="center" justifyContent="Left">
              <Typography mt={2}>
                Are you sure you want to delete the location group
              </Typography>
              <Typography mt={2} ml={0.5} fontWeight="800">
                {selectedGroup ? selectedGroup.AltText : ""}
              </Typography>
              <Typography mt={2}>?</Typography>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="Left">
              <Typography mt={2}>This group contains</Typography>
              <Typography mt={2} ml={0.5} mr={0.5} fontWeight="800">
                {selectedGroup ? selectedGroup.ChildCount : ""}
              </Typography>
              <Typography mt={2}>
                meters and these will be left unassigned.
              </Typography>
            </Box>
            <TextField
              id="delete-confirmation"
              label="Type the name to confirm"
              variant="outlined"
              fullWidth
              margin="normal"
              value={deleteConfirmation}
              onChange={(e) => setDeleteConfirmation(e.target.value)}
            />
            <Box mt={4} display="flex" justifyContent="space-between">
              <Button variant="contained" onClick={handleDeleteModalClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                disabled={
                  !selectedGroup || deleteConfirmation !== selectedGroup.AltText
                }
                onClick={() => {
                  try {
                    handleDeleteLocationGroup();
                    handleDeleteModalClose();
                  } catch (error) {
                    console.log("Failed to Delete Location Group.");
                  }
                }}
              >
                Delete
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};

export default Map;
