import React, { useState, useEffect } from "react";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheckSquare,
    faSquare,
    faChevronRight,
    faChevronDown,
    faPlusSquare,
    faMinusSquare,
    faFolder,
    faFolderOpen,
    faLeaf,
} from "@fortawesome/free-solid-svg-icons";
import {
    Box,
    Button,
    IconButton,
    Modal,
    Typography,
    Chip,
    ButtonGroup,
    Divider,
    TextField,
} from "@mui/material";
import Spinner from "../../components/Global/Spinner.jsx";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useLocation } from "react-router-dom";
import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";
import { fetchAuthSession } from "aws-amplify/auth";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import MeterData from "../../data/meterDataQuery";
import MeterReadings from "../../data/meterReadingsQuery";
import dayjs from "dayjs";
import FindIcon from "../../components/Global/utilityIcons";
import { styled } from "@mui/system";
import EditTreeComponent from "./EditTreeComponent.jsx";
import ApartmentIcon from "@mui/icons-material/Apartment";

function getNodeIds(nodes) {
    if (!nodes) return [];
    return nodes.reduce((ids, node) => {
        ids.push(node.value);
        if (node.children && node.children.length > 0) {
            ids.push(...getNodeIds(node.children));
        }
        return ids;
    }, []);
}

const StyledTreeContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "24vw",
    height: "100%",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    textOverflow: "ellipsis",
}));

const StyledCheckboxTreeContainer = styled(Box)(({ theme, colors }) => ({
  "& .rct-node-icon .fa-folder *": {
      color: colors.greenAccent[400],
      fontSize: "0.6rem",
      padding: 0
  },
  "& .rct-node-icon": {
    padding: 0
  },
  "& .rct-node-label": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "100%",
      borderRadius: "5px",
      fontSize: "0.6rem",
  },
  "& .rct-title, .rct-node-clickable": {
      fontSize: "0.6rem",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "100%",
      borderRadius: "5px",
  },
  "& .rct-node-clickable:hover, .rct-node-clickable:active, .rct-node-clickable:focus": {
      backgroundColor: theme.palette.action.hover,
  },
  "& .rct-node-icon .fa-chevron-right, .fa-chevron-down, .fa-plus-square, .fa-minus-square": {
      fontSize: "0.6rem",
  },
}));


const Tree = ({
    updateMeterVariable,
    updateMeterDataVariable,
    updateMeterReadingsVariable,
    colors,
}) => {
    const today = dayjs();
    const aMonthAgo = dayjs().subtract(1, "month");

    const [treeData, setTreeData] = useState(null);
    const [startDate, setStartDate] = useState(aMonthAgo);
    const [dateStamp, setDateStamp] = useState(today);
    const [endDate, setEndDate] = useState(today);
    const location = useLocation();
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openFetchModal, setOpenFetchModal] = useState(false);
    const [abortController, setAbortController] = useState(null);
    const [progress, setProgress] = useState(0);
    const [expanded, setExpanded] = useState([]);
    const [checked, setChecked] = useState([]);
    const [lastLoadedProfile, setLastLoadedProfile] = useState([]);
    const [hasProfileBeenLoaded, setHasProfileBeenLoaded] = useState(false);
    const [expandCollapseState, setExpandCollapseState] = useState(true);
    const [toggleState, setToggleState] = useState(true);

    const isProfileChanged = () => {
        return (
            JSON.stringify(checked.sort()) !==
            JSON.stringify(lastLoadedProfile.sort())
        );
    };

    const handleOpenEditModal = () => setOpenEditModal(true);
    const handleCloseEditModal = () => setOpenEditModal(false);

    const handleOpenFetchModal = () => setOpenFetchModal(true);
    const handleCloseFetchModal = () => {
        if (abortController) {
            abortController.abort();
        }
        setOpenFetchModal(false);
    };

    const handleFetchData = async (meterTreeIds, startDate, endDate) => {
        console.log("MeterTreeIds:", meterTreeIds);

        const meterList = meterTreeIds;
        console.log("Meter List:", meterList);

        const controller = new AbortController();
        setAbortController(controller);
        handleOpenFetchModal();

        try {
            const data = await MeterData.fetchAllMeterData(
                meterList,
                startDate,
                endDate,
                controller.signal,
                setProgress
            );
            updateMeterDataVariable(data);
        } catch (error) {
            if (error.name === "AbortError") {
                console.log("Fetch aborted");
            } else {
                console.error("Error fetching data:", error);
            }
        } finally {
            setOpenFetchModal(false);
        }
    };

    const getNodeById = (nodes, id) => {
        for (let node of nodes) {
            if (node.value === id) {
                return node;
            }
            if (node.children) {
                const found = getNodeById(node.children, id);
                if (found) {
                    return found;
                }
            }
        }
        return null;
    };

    const handleFetchReadings = async (checkedNodes, dateStamp) => {
        const meterList = checkedNodes
            .filter((nodeId) => {
                const node = getNodeById(treeData, nodeId);
                return node && (!node.children || node.children.length === 0);
            })
            .map((nodeId) => getNodeById(treeData, nodeId).meterTreeId);

        try {
            const data = await MeterReadings.fetchAllMeterReadings(
                meterList,
                dateStamp
            );
            updateMeterReadingsVariable(data);
        } catch (error) {
            console.error("Error fetching readings:", error);
        }
    };

    const fetchUserDataAndTree = async () => {
        setTreeData([]);
        console.log("Refreshing Tree...");
        try {
            const session = await fetchAuthSession();
            const token = session.tokens.accessToken || session.tokens.idToken;
            const response = await fetch(
                "https://www.data.mygreenvision.net/metertree",
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            const parsedData = JSON.parse(data.body);
            const formattedTreeData = buildTree(parsedData);
            setTreeData(formattedTreeData);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const toggleCheckboxesVisibility = () => {
        const checkboxes = document.querySelectorAll(".rct-checkbox");
        if (location.pathname === "/meters") {
            checkboxes.forEach((checkbox) => {
                checkbox.style.display = "none";
            });
        } else {
            checkboxes.forEach((checkbox) => {
                checkbox.style.display = "inline";
            });
        }
    };

    useEffect(() => {
        fetchUserDataAndTree();
    }, []);

    useEffect(() => {
        toggleCheckboxesVisibility();

        const observer = new MutationObserver(() => {
            toggleCheckboxesVisibility();
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true,
        });

        return () => {
            observer.disconnect();
        };
    }, [location, treeData]);

    useEffect(() => {
        if (treeData) {
            const folderTreeIds = getNodeIds(treeData).filter((nodeId) => {
                const node = treeData.find((node) => node.value === nodeId);
                return node && !node.meterId;
            });
            setExpanded(folderTreeIds);
        }
    }, [treeData]);

    const handleExpand = (newExpanded) => {
        setExpanded(newExpanded);
    };

    const handleChecked = (checked) => {
        const meterTreeIds = checked
            .map((nodeId) => {
                const node = getNodeById(treeData, nodeId);
                return node ? node.meterTreeId : null;
            })
            .filter((meterTreeId) => meterTreeId !== null);

        setChecked(checked);
        console.log("Checked:", checked);
        console.log("MeterTreeIds:", meterTreeIds);
    };

    const toggleExpandCollapse = () => {
        if (expandCollapseState) {
            setExpanded(getNodeIds(treeData));
        } else {
            setExpanded([]);
        }
        setExpandCollapseState(!expandCollapseState);
    };

    const allNodeIds = getNodeIds(treeData);

    const toggleAll = (nodeIds) => {
        if (toggleState) {
            setChecked(nodeIds);
        } else {
            setChecked([]);
        }
        setToggleState(!toggleState);
    };

    const dualDateSelect = ["/meterdata"].includes(location.pathname);
    const singleDateSelect = ["/meterreadings"].includes(location.pathname);

    const handleNodeClick = (targetNode) => {
        if (!targetNode.isParent) {
            console.log(targetNode);
            updateMeterVariable(targetNode);
        } else {
            console.log("Selected Node is a Folder");
        }
    };

    const buildTree = (items) => {
        let rootItemsMap = {};
        let lookup = {};

        // Initialize the root items map and lookup object
        items.forEach((item) => {
            const rootId = item["MeterTreesID"];
            const rootLabel = item["FriendlyName"] || "Unnamed Root";

            if (!rootItemsMap[rootId]) {
                rootItemsMap[rootId] = {
                    label: rootLabel,
                    value: rootId,
                    children: [],
                    icon: (
                        <ApartmentIcon
                            style={{ color: colors.greenAccent[400] }}
                        />
                    ),
                };
            }

            if (item["ChildCount"] > 0) {
                lookup[item["MeterTreeID"]] = { ...item, children: [] };
            } else {
                lookup[item["MeterTreeID"]] = { ...item };
            }
        });

        // Populate the tree structure
        items.forEach((item) => {
            const itemId = item["MeterTreeID"];
            const parentId = item["ParentID"];
            const itemLabel = item["AltText"] || item["MeterName"] || "Unnamed";
            const rootId = item["MeterTreesID"];
            const meterTreeId = item["MeterTreeID"];
            let newItem = {};

            if (item["MeterID"]) {
                const nodeIcon = (
                    <FindIcon
                        colors={colors}
                        utilityId={parseInt(item["UtilityID"])}
                        utilitySubtypeId={parseInt(item["UtilitySubtypeID"])}
                    />
                );
                newItem = {
                    label: itemLabel,
                    value: itemId,
                    icon: nodeIcon,
                    meterTreeId: meterTreeId,
                };
            } else if (item["ChildCount"] > 0) {
                const nodeIcon = (
                    <FindIcon
                        colors={colors}
                        utilityId={100}
                        utilitySubtypeId={null}
                    />
                );
                newItem = {
                    label: itemLabel,
                    value: itemId,
                    icon: nodeIcon,
                    meterTreeId: meterTreeId,
                };
            } else if (!item["UtilityID"]) {
                const nodeIcon = (
                    <FindIcon
                        colors={colors}
                        utilityId={0}
                        utilitySubtypeId={null}
                    />
                );
                newItem = {
                    label: itemLabel,
                    value: itemId,
                    icon: nodeIcon,
                    meterTreeId: meterTreeId,
                };
            } else {
                newItem = {
                    label: itemLabel,
                    value: itemId,
                    meterTreeId: meterTreeId,
                };
            }

            lookup[itemId] = { ...lookup[itemId], ...newItem };

            if (!parentId) {
                // Add to root items if no parent ID
                rootItemsMap[rootId].children.push(lookup[itemId]);
            } else {
                // Otherwise, add to the respective parent node's children array
                if (lookup[parentId]) {
                    if (!lookup[parentId].children) {
                        lookup[parentId].children = [];
                    }
                    lookup[parentId].children.push(lookup[itemId]);
                }
            }
        });

        // Convert root items map to array format
        return Object.values(rootItemsMap);
    };

    return (
        <StyledTreeContainer>
            <Modal
                open={openFetchModal}
                onClose={handleCloseFetchModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        maxWidth: "24vw",
                        minWidth: "24vw",
                        bgcolor: colors.primary[400],
                        borderRadius: "20px",
                        boxShadow: 24,
                        p: 4,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        overflow: "visible",
                    }}
                >
                    <Box
                        width="100%"
                        height="100%"
                        overflow="visible"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Spinner colors={colors} />
                    </Box>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        pt="20px"
                        pb="10px"
                        fontWeight="600"
                        fontSize="1.4rem"
                    >
                        {progress}%
                    </Typography>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        p="20px"
                        fontWeight="600"
                        fontSize="1.4rem"
                        textAlign="center"
                    >
                        Loading Data...
                    </Typography>
                    <Button
                        onClick={handleCloseFetchModal}
                        variant="contained"
                        color="error"
                    >
                        <CloseIcon />
                        Cancel
                    </Button>
                </Box>
            </Modal>

            <Modal
                open={openEditModal}
                onClose={handleCloseEditModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "80vw",
                        bgcolor: colors.primary[400],
                        boxShadow: 24,
                        p: 4,
                        borderRadius: "5px",
                    }}
                >
                    <Box
                        sx={{
                            width: "100%",
                            height: "30px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "left",
                            marginBottom: "10px",
                        }}
                    >
                        <Button
                            variant="outlined"
                            onClick={handleCloseEditModal}
                        >
                            Close
                        </Button>
                        <Box width="10px" />
                        <ButtonGroup>
                            <Button variant="outlined">Save</Button>
                            <Button variant="outlined">Reset</Button>
                        </ButtonGroup>
                        <Box width="10px" />
                        <ButtonGroup>
                            <Button variant="outlined">Undo</Button>
                            <Button variant="outlined">Redo</Button>
                        </ButtonGroup>
                        <Box width="10px" />
                        <Typography>
                            New: 0, Modified: 0, Moved: 0, Deleted: 0
                        </Typography>
                    </Box>
                    <Divider />
                    <EditTreeComponent
                        width="100%"
                        colors={colors}
                        updateMeterVariable={updateMeterVariable}
                        updateMeterDataVariable={updateMeterDataVariable}
                        updateMeterReadingsVariable={
                            updateMeterReadingsVariable
                        }
                        handleNodeClick={handleNodeClick} // Pass the handleNodeClick as a prop
                    />
                </Box>
            </Modal>

            <Box
                width="100%"
                height="100%"
                sx={{ backgroundColor: colors.primary[400] }}
            >
                <Box
                    display="flex"
                    height="50px"
                    width="100%"
                    sx={{
                        backgroundColor: colors.greenAccent[400],
                        justifyContent:
                            hasProfileBeenLoaded && isProfileChanged()
                                ? "space-between"
                                : "flex-end",
                        borderRadius: "0 5px 0 0 ",
                    }}
                    p="5px"
                    alignItems="center"
                >
                    <Box>
                        {location.pathname !== "/meters" &&
                            isProfileChanged() &&
                            hasProfileBeenLoaded && (
                                <Chip
                                    color="warning"
                                    icon={<ReportOutlinedIcon />}
                                    sx={{
                                        height: "auto",
                                        "& .MuiChip-label": {
                                            display: "block",
                                            whiteSpace: "normal",
                                        },
                                    }}
                                    label="Warning, changes have been made. Please load re-load data."
                                />
                            )}
                    </Box>

                    <Box>
                        <IconButton onClick={fetchUserDataAndTree}>
                            <AutorenewOutlinedIcon />
                        </IconButton>
                        <IconButton onClick={handleOpenEditModal}>
                            <CreateOutlinedIcon />
                        </IconButton>
                    </Box>
                </Box>

                <Box
                    max-width="100%"
                    display="flex"
                    height="92%"
                    maxHeight="72vh"
                    overflow="scroll"
                    justifyContent="left"
                    alignItems="top"
                    flex-grow="1"
                    sx={{ overflowY: "scroll", overflowX: "scroll" }}
                >
                    {treeData && treeData.length > 0 ? (
                        <StyledCheckboxTreeContainer colors={colors}>
                            <CheckboxTree
                                width="100%"
                                height="100%"
                                minHeight="72vh"
                                overflowX="scroll"
                                onCheck={handleChecked}
                                orderable="True"
                                expanded={expanded}
                                icons={{
                                    check: (
                                        <FontAwesomeIcon
                                            icon={faCheckSquare}
                                            style={{
                                                color: colors.primary[200],
                                            }}
                                        />
                                    ),
                                    uncheck: (
                                        <FontAwesomeIcon
                                            icon={faSquare}
                                            style={{
                                                color: colors.primary[200],
                                            }}
                                        />
                                    ),
                                    halfCheck: (
                                        <FontAwesomeIcon
                                            icon={faMinusSquare}
                                            style={{
                                                color: colors.primary[200],
                                            }}
                                        />
                                    ),
                                    expandClose: (
                                        <FontAwesomeIcon
                                            icon={faChevronRight}
                                            style={{
                                                color: colors.primary[200],
                                            }}
                                        />
                                    ),
                                    expandOpen: (
                                        <FontAwesomeIcon
                                            icon={faChevronDown}
                                            style={{
                                                color: colors.primary[200],
                                            }}
                                        />
                                    ),
                                    expandAll: (
                                        <FontAwesomeIcon
                                            icon={faPlusSquare}
                                            style={{
                                                color: colors.greenAccent[400],
                                            }}
                                        />
                                    ),
                                    collapseAll: (
                                        <FontAwesomeIcon
                                            icon={faMinusSquare}
                                            style={{
                                                color: colors.greenAccent[400],
                                            }}
                                        />
                                    ),
                                    parentClose: (
                                        <FontAwesomeIcon
                                            icon={faFolder}
                                            style={{
                                                color: colors.greenAccent[400],
                                            }}
                                        />
                                    ),
                                    parentOpen: (
                                        <FontAwesomeIcon
                                            icon={faFolderOpen}
                                            style={{
                                                color: colors.greenAccent[400],
                                            }}
                                        />
                                    ),
                                    leaf: (
                                        <FontAwesomeIcon
                                            icon={faLeaf}
                                            style={{
                                                color: colors.greenAccent[400],
                                            }}
                                        />
                                    ),
                                }}
                                nodes={treeData}
                                checked={checked}
                                onExpand={handleExpand}
                                onClick={handleNodeClick}
                            />
                        </StyledCheckboxTreeContainer>
                    ) : (
                        <Box
                            width="100%"
                            height="100%"
                            overflow="visible"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Spinner colors={colors} />
                        </Box>
                    )}
                </Box>
            </Box>

            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-around"
                width="100%"
                p="10px"
            >
                {singleDateSelect && (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-around"
                        width="100%"
                    >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                value={dateStamp}
                                onChange={(newValue) => {
                                    setDateStamp(newValue);
                                }}
                            />
                        </LocalizationProvider>
                    </Box>
                )}
                {dualDateSelect && (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-around"
                        width="100%"
                    >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                value={startDate}
                                onChange={(newValue) => {
                                    setStartDate(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                value={endDate}
                                onChange={(newValue) => {
                                    setEndDate(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                            />
                        </LocalizationProvider>
                    </Box>
                )}
            </Box>

            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-around"
                width="100%"
                height="100%"
                p="5px"
            >
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: `${colors.greenAccent[600]} !important`,
                    }}
                    height="80%"
                    width="50%"
                    onClick={() => toggleAll(allNodeIds)}
                >
                    {toggleState ? "Select" : "Deselect"} All
                </Button>

                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: `${colors.greenAccent[600]} !important`,
                    }}
                    height="80%"
                    width="50%"
                    onClick={toggleExpandCollapse}
                >
                    {expandCollapseState ? "Expand" : "Collapse"} All
                </Button>

                {location.pathname !== "/meters" &&
                    location.pathname !== "/map" &&
                    location.pathname !== "/alerts" && (
                        <Button
                            variant="contained"
                            color="primary"
                            width="50%"
                            onClick={() =>
                                checked.length > 0
                                    ? location.pathname === "/meterdata"
                                        ? handleFetchData(
                                              checked,
                                              startDate,
                                              endDate
                                          )
                                        : handleFetchReadings(
                                              checked,
                                              dateStamp
                                          )
                                    : console.log("No meters selected.")
                            }
                        >
                            Load{" "}
                            {location.pathname === "/meterdata"
                                ? "Data"
                                : location.pathname === "/meterreadings"
                                ? "Readings"
                                : "Profile"}
                        </Button>
                    )}
            </Box>
        </StyledTreeContainer>
    );
};

export default Tree;
