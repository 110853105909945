import { useMemo, useState, useEffect, createContext } from "react";
import { createTheme } from "@mui/material/styles";
import { fetchUserAttributes } from 'aws-amplify/auth';
import chroma from 'chroma-js';

const defaultGreySwatch = "#3d3d3d"; // Default grey swatch color
const defaultGreenSwatch = "#388950"; // Default green swatch color

const generateShades = (baseColor) => {
  const resolvedColor = baseColor || defaultGreySwatch; // Change to defaultGreySwatch

  console.log("generateShades -> baseColor:", baseColor, ", resolvedColor:", resolvedColor);
  if (!resolvedColor) {
    console.error("Resolved color is undefined. Using default swatch as fallback.");
  }

  const darkenedBase = chroma(resolvedColor).darken(2).hex();
  const scale = chroma.scale([darkenedBase, resolvedColor, 'white']).mode('lab').colors(9);

  return {
    100: scale[8],
    200: scale[7],
    300: scale[6],
    400: scale[5],
    500: scale[4],
    600: scale[3],
    700: scale[2],
    800: scale[1],
    900: scale[0],
  };
};

const tokens = (mode = 'light', swatch = defaultGreySwatch) => { // Change to defaultGreySwatch
  const resolvedSwatch = swatch || defaultGreySwatch; // Change to defaultGreySwatch

  console.log("tokens -> mode:", mode, ", swatch:", swatch, ", resolvedSwatch:", resolvedSwatch);
  if (!resolvedSwatch) {
    console.error("Resolved swatch is undefined. Using default swatch as fallback.");
  }

  const greenAccent = generateShades(resolvedSwatch);

  return {
    ...(mode === "dark"
      ? {
          grey: {
            100: "#e0e0e0",
            200: "#c2c2c2",
            300: "#a3a3a3",
            400: "#858585",
            500: "#666666",
            600: "#525252",
            700: "#3d3d3d",
            800: "#292929",
            900: "#141414",
          },
          primary: {
            100: "#c7c7c7",
            200: "#8f8f8f",
            300: "#575757",
            400: "#3d3d3d",
            500: "#282828",
            600: "#1e1e1e",
            700: "#141414",
            800: "#0a0a0a",
            900: "#000000",
          },
          greenAccent,
          redAccent: {
            100: "#f8dcdb",
            200: "#f1b9b7",
            300: "#e99592",
            400: "#e2726e",
            500: "#db4f4a",
            600: "#af3f3b",
            700: "#832f2c",
            800: "#58201e",
            900: "#2c100f",
          },
          blueAccent: {
            100: "#e1e2fe",
            200: "#c3c6fd",
            300: "#a4a9fc",
            400: "#868dfb",
            500: "#6870fa",
            600: "#535ac8",
            700: "#3e4396",
            800: "#2a2d64",
            900: "#151632",
          },
          meterSelect: {
            100: "#282828",
            200: "#3f3f3f",
            300: "#575757",
            400: "#717171",
            500: "#1f1f1f",
            600: "#191919",
            700: "#121212",
            800: "#0b0b0b",
            900: "#050505",
          },
        }
      : {
          grey: {
            100: "#141414",
            200: "#292929",
            300: "#3d3d3d",
            400: "#525252",
            500: "#666666",
            600: "#858585",
            700: "#a3a3a3",
            800: "#c2c2c2",
            900: "#e0e0e0",
          },
          primary: {
            100: "#040509",
            200: "#080b12",
            300: "#0c101b",
            400: "#f2f0f0",
            500: "#141b2d",
            600: "#1F2A40",
            700: "#727681",
            800: "#a1a4ab",
            900: "#d0d1d5",
          },
          greenAccent,
          redAccent: {
            100: "#2c100f",
            200: "#58201e",
            300: "#832f2c",
            400: "#af3f3b",
            500: "#db4f4a",
            600: "#e2726e",
            700: "#e99592",
            800: "#f1b9b7",
            900: "#f8dcdb",
          },
          blueAccent: {
            100: "#151632",
            200: "#2a2d64",
            300: "#3e4396",
            400: "#535ac8",
            500: "#6870fa",
            600: "#868dfb",
            700: "#a4a9fc",
            800: "#c3c6fd",
            900: "#e1e2fe",
          },
          meterSelect: {
            100: "#e6e6e6",
            200: "#f0f0f0",
            300: "#f0f0f0",
            400: "#f0f0f0",
            500: "#f0f0f0",
            600: "#f0f0f0",
            700: "#f0f0f0",
            800: "#f0f0f0",
            900: "#f0f0f0",
          },
        }),
  };
};

const themeSettings = (mode, swatch) => {
  const colors = tokens(mode, swatch);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            primary: {
              main: colors.primary[500],
            },
            secondary: {
              main: colors.greenAccent[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: colors.primary[500],
            },
          }
        : {
            primary: {
              main: colors.primary[100],
            },
            secondary: {
              main: colors.greenAccent[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: "#fcfcfc",
            },
          }),
    },
    typography: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};

export const ColorModeContext = createContext({
  toggleColorMode: () => {},
  setMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState("light");
  const [swatch, setSwatch] = useState(defaultGreySwatch); // Initialise swatch as grey
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAndSetSwatch = async () => {
      try {
        const cachedSwatch = localStorage.getItem('swatch');
        const cachedMode = localStorage.getItem('mode');
        const cachedUserId = localStorage.getItem('userId');

        const userAttributes = await fetchUserAttributes();
        const swatchValue = userAttributes['custom:swatch'];
        const initColorMode = userAttributes['custom:colorMode'] || "light";
        const userId = userAttributes['sub']; // Assuming 'sub' is the unique user identifier

        if (cachedUserId !== userId || !cachedSwatch || !cachedMode) {
          localStorage.removeItem('swatch');
          localStorage.removeItem('mode');
          localStorage.setItem('userId', userId);
          localStorage.setItem('swatch', swatchValue || defaultGreenSwatch); // Use default green swatch if user swatch is not set
          localStorage.setItem('mode', initColorMode);
          setSwatch(swatchValue || defaultGreenSwatch); // Set swatch to user swatch or default green swatch
          setMode(initColorMode);
        } else {
          setSwatch(cachedSwatch);
          setMode(cachedMode);
        }
      } catch (error) {
        console.error("Error fetching user attributes:", error);
        localStorage.removeItem('swatch');
        localStorage.removeItem('mode');
        setSwatch(defaultGreySwatch); // Set swatch to default grey on error
        setMode("light"); // Set mode to "light" on error
      } finally {
        setLoading(false);
      }
    };
    fetchAndSetSwatch();
  }, []);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => {
          const newMode = prev === "light" ? "dark" : "light";
          localStorage.setItem('mode', newMode);
          return newMode;
        }),
      setMode: (newMode) => {
        localStorage.setItem('mode', newMode);
        setMode(newMode);
      },
    }),
    []
  );

  const theme = useMemo(() => {
    return createTheme(themeSettings(mode, swatch));
  }, [mode, swatch]);

  return [theme, colorMode, loading];
};

export { tokens, themeSettings };
