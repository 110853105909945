import { fetchAuthSession } from 'aws-amplify/auth';
import notification from '../components/Global/Toast';

const createSubUser = async (newSubUser) => {
  try {
    const session = await fetchAuthSession();
    const token = session.tokens.accessToken || session.tokens.idToken;

    const response = await fetch("https://www.data.mygreenvision.net/SubUser/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        username: newSubUser.username,
        email: newSubUser.email,
        password: newSubUser.password
      }), // Ensure structure matches lambda
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(`Code: ${data.statusCode}`);
    }

    notification("success", "Sub-user created successfully");
    return data;
  } catch (error) {
    console.error("Error creating sub-user:", error);
    notification("error", "Failed to create sub-user");
    throw error;
  }
};

export default createSubUser;
