import { faFolder, faSun, faPlug, faDroplet, faTemperatureThreeQuarters, faPercent, faToggleOn, faPersonShelter, faGasPump, faCloud, faFireFlameSimple, faOilCan, faExclamation } from '@fortawesome/free-solid-svg-icons';
import 'font-awesome/css/font-awesome.min.css';

function findSVGIcon(utilityId, utilitySubtypeId) {
    const utilIcons = {
        100: faFolder,
        0: faExclamation,
        1: faPlug,
        2: faFireFlameSimple,
        3: faFireFlameSimple,
        4: faDroplet,
        5: faPercent,
        6: faTemperatureThreeQuarters,
        7: faToggleOn,
        8: faTemperatureThreeQuarters,
        9: faDroplet,
        10: faFireFlameSimple,
        11: faSun,
        12: faPersonShelter,
        13: faGasPump,
        14: faGasPump,
        15: faGasPump,
        16: faCloud,
        17: faFireFlameSimple,
        18: faCloud,
        19: faOilCan,
    };

    const utilSubIcons = {
        3: faSun,
        10: faSun,
    }

    if (utilitySubtypeId !== null && (utilitySubtypeId === 3 || utilitySubtypeId === 10)) {
        return utilSubIcons[utilitySubtypeId];
    } else {
        return utilIcons[utilityId];
    }
}

export default findSVGIcon;
