import React, { useState, useEffect } from "react";
import {
  Box,
  useTheme,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  CircularProgress,
  IconButton,
  Button,
  Modal,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import Header from "../../components/Global/Header";
import ListSubUsers from "../../data/subUsersQuery";
import createSubUser from "../../data/createSubUser";
import modifySubUser from "../../data/modifySubUser";
import deleteSubUser from "../../data/deleteSubUser";
import Permissions from "../../data/permissionsTranslate";

const Subusers = ({ colors }) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [subUsers, setSubUsers] = useState([]);
  const [masterUser, setMasterUser] = useState(null);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [newSubUser, setNewSubUser] = useState({
    username: "",
    email: "",
    password: "",
    permission: "",
  });
  const [editedSubUser, setEditedSubUser] = useState({
    id: "",
    username: "",
    newUsername: "",
    email: "",
    password: "",
    permission: "",
  });
  const [deleteSubUserName, setDeleteSubUserName] = useState("");
  const [deleteConfirmationName, setDeleteConfirmationName] = useState("");

  useEffect(() => {
    const fetchSubUserData = async () => {
      setLoading(true);
      try {
        const data = await ListSubUsers.getSubUsers();
        const allUsers = data.SubUsers || [];
        const master = allUsers.find((user) => user.ParentID === null);
        const subUsersList = allUsers.filter((user) => user.ParentID !== null);
        setMasterUser(master);
        setSubUsers(subUsersList);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchSubUserData();
  }, []);

  const handleCreateModalOpen = () => setCreateModalOpen(true);
  const handleCreateModalClose = () => setCreateModalOpen(false);

  const handleEditModalOpen = (subUser) => {
    setEditedSubUser({
      id: subUser.id,
      username: subUser.UserName,
      newUsername: subUser.UserName,
      email: subUser.email,
      password: "",
      permission: subUser.UserPermissionID,
    });
    setEditModalOpen(true);
  };

  const handleEditModalClose = () => setEditModalOpen(false);
  const handleDeleteModalOpen = (subUserName) => {
    setDeleteSubUserName(subUserName);
    setDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => setDeleteModalOpen(false);

  const handleCreateSubUser = async () => {
    try {
      await createSubUser(newSubUser);
      setSubUsers((prevSubUsers) => [...prevSubUsers, newSubUser]);
      handleCreateModalClose();
    } catch (error) {
      console.error("Error creating sub-user:", error);
    }
  };

  const handleEditSubUser = async () => {
    try {
      const { id, username, newUsername, email, password, permission } = editedSubUser;
      const updatedSubUser = {
        id,
        username,
        newUsername,
        email,
        password,
        permission,
      };
      await modifySubUser(updatedSubUser);
      setSubUsers((prevSubUsers) =>
        prevSubUsers.map((user) =>
          user.id === id ? { ...user, UserName: newUsername || username, email, UserPermissionID: permission } : user
        )
      );
      handleEditModalClose();
    } catch (error) {
      console.error("Error modifying sub-user:", error);
    }
  };

  const handleDeleteSubUser = async () => {
    try {
      await deleteSubUser(deleteSubUserName);
      setSubUsers((prevSubUsers) =>
        prevSubUsers.filter((user) => user.UserName !== deleteSubUserName)
      );
      handleDeleteModalClose();
    } catch (error) {
      console.error("Error deleting sub-user:", error);
    }
  };

  return (
    <Box m="20px" width="100%">
      <Header
        title="Sub-Users"
        subtitle="Set up Sub-Users with provisioned access to master account resources."
        colors={colors}
      />
      <Box display="flex" width="100%" height="80vh">
        <Box height="100%" width="100%">
          <Box
            width="100%"
            height="5%"
            padding="8px"
            backgroundColor={colors.greenAccent[400]}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderRadius="5px 5px 0 0"
          >
            <Typography sx={{ fontWeight: "800" }}>Sub-Users</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateModalOpen}
            >
              Create Sub-User
            </Button>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            height="95%"
            overflow="hidden"
            width="100%"
          >
            <TableContainer
              style={{
                flex: 1,
                overflowY: "auto",
                backgroundColor: theme.palette.background.paper,
              }}
              width="100%"
              borderRadius="0 0 5px 5px"
            >
              <Table
                size="small"
                stickyHeader
                width="100%"
                borderRadius="0 0 5px 5px"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        backgroundColor: theme.palette.grey[800],
                        cursor: "pointer",
                      }}
                    >
                      <Box display="flex" alignItems="center">
                        UserName
                      </Box>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: theme.palette.grey[800],
                        cursor: "pointer",
                      }}
                    >
                      <Box display="flex" alignItems="center">
                        Last Login
                      </Box>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: theme.palette.grey[800],
                        cursor: "pointer",
                      }}
                    >
                      <Box display="flex" alignItems="center">
                        Permissions
                      </Box>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: theme.palette.grey[800],
                        cursor: "pointer",
                      }}
                    >
                      <Box display="flex" alignItems="center">
                        Edit
                      </Box>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: theme.palette.grey[800],
                        cursor: "pointer",
                      }}
                    >
                      <Box display="flex" alignItems="center">
                        Delete
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  ) : (
                    subUsers.map((subUser, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:hover": {
                            backgroundColor: theme.palette.grey[400],
                          },
                        }}
                      >
                        <TableCell
                          sx={{
                            padding: "3px 0 3px 5px",
                            backgroundColor: theme.palette.grey[900],
                          }}
                        >
                          {subUser.UserName || "Placeholder Username"}
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "3px 0 3px 0",
                            backgroundColor: theme.palette.grey[900],
                          }}
                        >
                          {subUser.LastLogin || "Placeholder Timestamp"}
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "3px",
                            backgroundColor: theme.palette.grey[900],
                          }}
                        >
                          {Permissions.permissionsTranslate(
                            subUser.UserPermissionID
                          ) || "Placeholder Permission"}
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "3px",
                            backgroundColor: theme.palette.grey[900],
                          }}
                        >
                          <IconButton
                            onClick={() => handleEditModalOpen(subUser)}
                          >
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "3px",
                            backgroundColor: theme.palette.grey[900],
                          }}
                        >
                          <IconButton
                            onClick={() =>
                              handleDeleteModalOpen(subUser.UserName)
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>

      <Modal open={createModalOpen} onClose={handleCreateModalClose}>
        <Box
          position="absolute"
          top="50%"
          left="50%"
          style={{ transform: "translate(-50%, -50%)", zIndex: 1300 }}
          width="50vw"
          bgcolor="background.paper"
          boxShadow={24}
          p={4}
          borderRadius="5px"
        >
          <Typography variant="h4" component="h4">
            Create Sub-User
          </Typography>
          <TextField
            label="Username"
            variant="filled"
            fullWidth
            margin="normal"
            value={newSubUser.username}
            onChange={(e) =>
              setNewSubUser({ ...newSubUser, username: e.target.value })
            }
            autoComplete="new-password"
          />
          <TextField
            label="Email"
            variant="filled"
            fullWidth
            margin="normal"
            value={newSubUser.email}
            onChange={(e) =>
              setNewSubUser({ ...newSubUser, email: e.target.value })
            }
            autoComplete="new-password"
          />
          <TextField
            label="Password"
            variant="filled"
            fullWidth
            margin="normal"
            type="password"
            value={newSubUser.password}
            onChange={(e) =>
              setNewSubUser({ ...newSubUser, password: e.target.value })
            }
            autoComplete="new-password"
          />
          <FormControl fullWidth variant="filled" margin="normal">
            <InputLabel>Permissions</InputLabel>
            <Select
              value={newSubUser.permission}
              onChange={(e) =>
                setNewSubUser({ ...newSubUser, permission: e.target.value })
              }
              autoComplete="new-password"
            >
              {Object.entries(Permissions.permissionsTable).map(
                ([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button variant="contained" onClick={handleCreateModalClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCreateSubUser}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={editModalOpen} onClose={handleEditModalClose}>
        <Box
          position="absolute"
          top="50%"
          left="50%"
          style={{ transform: "translate(-50%, -50%)", zIndex: 1300 }}
          width="50vw"
          bgcolor="background.paper"
          boxShadow={24}
          p={4}
          borderRadius="5px"
        >
          <Typography variant="h4" component="h4">
            Edit Sub-User
          </Typography>
          <TextField
            label="Username"
            variant="filled"
            fullWidth
            margin="normal"
            value={editedSubUser.newUsername}
            onChange={(e) =>
              setEditedSubUser({ ...editedSubUser, newUsername: e.target.value })
            }
            autoComplete="new-password"
          />
          <TextField
            label="Email"
            variant="filled"
            fullWidth
            margin="normal"
            value={editedSubUser.email}
            onChange={(e) =>
              setEditedSubUser({ ...editedSubUser, email: e.target.value })
            }
            autoComplete="new-password"
          />
          <TextField
            label="Password"
            variant="filled"
            fullWidth
            margin="normal"
            type="password"
            value={editedSubUser.password}
            onChange={(e) =>
              setEditedSubUser({ ...editedSubUser, password: e.target.value })
            }
            autoComplete="new-password"
          />
          <FormControl fullWidth variant="filled" margin="normal">
            <InputLabel>Permissions</InputLabel>
            <Select
              value={editedSubUser.permission}
              onChange={(e) =>
                setEditedSubUser({ ...editedSubUser, permission: e.target.value })
              }
              autoComplete="new-password"
            >
              {Object.entries(Permissions.permissionsTable).map(
                ([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button variant="contained" onClick={handleEditModalClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleEditSubUser}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={deleteModalOpen} onClose={handleDeleteModalClose}>
        <Box
          position="absolute"
          top="50%"
          left="50%"
          style={{ transform: "translate(-50%, -50%)", zIndex: 1300 }}
          width="30vw"
          bgcolor="background.paper"
          boxShadow={24}
          p={4}
          borderRadius="5px"
        >
          <Typography variant="h4" component="h4">
            Confirm Deletion
          </Typography>
          <Typography mt={2}>
            Are you sure you want to delete this sub-user? Please type the
            username to confirm.
          </Typography>
          <TextField
            variant="filled"
            fullWidth
            margin="normal"
            value={deleteConfirmationName}
            onChange={(e) => setDeleteConfirmationName(e.target.value)}
            autoComplete="new-password"
          />
          <Box display="flex" justifyContent="space-between" mt={4}>
            <Button variant="contained" onClick={handleDeleteModalClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleDeleteSubUser}
              disabled={deleteConfirmationName !== deleteSubUserName}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Subusers;
