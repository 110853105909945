import React from "react";
import {
  Box,
  Button,
  Select,
  MenuItem,
  Typography,
  IconButton
} from "@mui/material";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { useLocation } from 'react-router-dom';

  const CustomTablePagination = ({
    count,
    page,
    rowsPerPage,
    onPageChange,
    onRowsPerPageChange,
    onSaveClick,
    handleOpen,
    actionTitle,
    actionDisabled,
    secondaryActionTitle,
    secondaryActionDisabled,
    secondaryActionTheme,
    actionTheme,
    resetChanges,
    colors
  }) => {

  const location = useLocation();

  const findCorrectFunction = (title) => {
    if(title === "Save"){
      onSaveClick()
    } else if(title === "Add Location") {
      handleOpen()
    } else if(title === "Reset") {
      resetChanges()
    }
  }

  function routedPageScope() {
    const currentRoute = location.pathname;
    if(currentRoute === "/alerts"){
      return([50, 100])
    } else if(currentRoute === "/map") {
      return([10, 20, 30, 40, 50])
    } else {
      return([10, 20, 30, 40, 50, 60, 70, 80, 90, 100])
    }
  }

    return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      padding="3px 16px 3px 16px"
    >
      <Box display="flex" alignItems="center">
        <Typography variant="body2">Rows per page:</Typography>
        <Select
          value={rowsPerPage}
          onChange={onRowsPerPageChange}
          style={{ marginLeft: "8px", marginRight: "8px" }}
          size="small"
        >
          {routedPageScope().map((rows) => (
            <MenuItem key={rows} value={rows}>
              {rows}
            </MenuItem>
          ))}
        </Select>

        <Box sx={{ marginLeft: "8px", marginRight: "8px" }}>
          <IconButton
            onClick={(event) => onPageChange(event, page - 1)}
            disabled={page === 0}
          >
            <ChevronLeftOutlinedIcon />
          </IconButton>
          <IconButton
            onClick={(event) => onPageChange(event, page + 1)}
            disabled={(page + 1) * rowsPerPage >= count}
          >
            <ChevronRightOutlinedIcon />
          </IconButton>
        </Box>

        <Typography
          variant="body2"
          sx={{ marginLeft: "8px", marginRight: "8px" }}
        >
          {page * rowsPerPage + 1}-{Math.min((page + 1) * rowsPerPage, count)}{" "}
          of {count}
        </Typography>
      </Box>
      <Box
        sx={{
          Button: {
            margin: '5px'
          }
        }}
      >
        {secondaryActionTitle && (
          <Button
            variant="contained"
            color= {secondaryActionTheme}
            onClick={() => findCorrectFunction(secondaryActionTitle)}
            disabled={secondaryActionDisabled}
          >
            {secondaryActionTitle}
          </Button>
        )}
        {actionTitle && (
          <Button
            variant="contained"
            color= {actionTheme}
            onClick={() => findCorrectFunction(actionTitle)}
            disabled={actionDisabled}
          >
            {actionTitle}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default CustomTablePagination;
