import { fetchAuthSession } from 'aws-amplify/auth';

const Meter = {
  fetchMeter: async (meterTreeID) => {
    console.log("Fetching Meter Data...");
    try {
      const session = await fetchAuthSession();
      const token = session.tokens.accessToken || session.tokens.idToken;

      const queryParams = new URLSearchParams({
        meterTreeID: meterTreeID
      }).toString();

      const url = `https://www.data.mygreenvision.net/meter?${queryParams}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Retrieved Data: ", data);
      return data; // Ensure data is returned

    } catch (error) {
      console.error('Error fetching data:', error);
      throw error; // Rethrow the error to be handled by the caller
    }
  }
};

export default Meter;