import { Box } from "@mui/material";
import Header from "../../components/Global/Header";


const MeterReadings = ({ colors }) => {

  return (
    <Box m="20px" width="100%" height="100%">
      <Header title="Meter Readings" subtitle="View or Export Meter Readings"  colors={colors}/>
    </Box>
  );
};

export default MeterReadings;
