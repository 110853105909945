// DeviceCheck.js
import React from 'react';
import { isMobile } from 'react-device-detect';

const DeviceCheck = ({ children }) => {
  if (isMobile) {
    return (
      <div>
        <h1>Access Denied</h1>
        <p>This application is only accessible on desktop browsers.</p>
      </div>
    );
  }
  return children;
};

export default DeviceCheck;