import { toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function notification(type, message, theme) {

    const themeState = !["error", "info", "warning", "success"].includes(type);

    const emojis = {
        error: "🔥",
        success: "🎉",
        info: "💡",
        warning: "⚠️",
        light: "☀️",
        dark: "🌙"
    };

    const toastConf = {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: theme,
        transition: Bounce,
        icon: false
    };

    function typeFallback(type) {
        return themeState ? "success" : type;
    };

    const msg = `<div style="display: flex; align-items: center; justify-content: left;"><span style="font-size: 1.7rem; margin-right: 10px;">${emojis[type]}</span>${message}<div>`;

    toast[typeFallback(type)](<div dangerouslySetInnerHTML={{ __html: msg }} />, toastConf);
}

export default notification;
