import { Box, Tooltip } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import RemoveIcon from '@mui/icons-material/Remove';

function utilityPolarity(utilityId, utilitySubtypeId) {
    const utilSubPolarity = {
        3: true, // generation
        10: true, // generation
        11: false, // consumption
    };

    const utilPolarity = {
        0: false, // consumption
        1: false, // consumption
        2: false, // consumption
        3: false, // consumption
        4: false, // consumption
        5: false, // consumption
        6: false, // consumption
        7: false, // consumption
        8: false, // consumption
        9: false, // consumption
        10: false, // consumption
        11: true, // generation
        12: false, // consumption
        13: false, // consumption
        14: false, // consumption
        15: false, // consumption
        16: false, // consumption
        17: false, // consumption
        18: false, // consumption
        19: false, // consumption
    };

    return utilSubPolarity[utilitySubtypeId] ?? utilPolarity[utilityId];
}

const DiffIndicator = ({ colors = {}, utilityId, utilitySubtypeId, units, year, month }) => {
    const appliedColors = {
        redAccent: { 500: '#ff0000' }, // Default red color
        greenAccent: '#80de78',
        neutral: colors.grey?.[400] || '#b0b0b0'
    };

    const polarity = utilityPolarity(utilityId, utilitySubtypeId);
    const difference = !polarity ? Math.round((year / 12) - month) : month - Math.round((year / 12)) ;
    const isNeutral = difference === 0;
    const isPositive = difference > 0;
    const isNegative = difference < 0;

    const isGeneration = polarity;
    const isBad = (isGeneration && isNegative) || (!isGeneration && isPositive);
    const color = isNeutral ? appliedColors.neutral : (isBad ? appliedColors.redAccent[500] : appliedColors.greenAccent);

    const message = isNeutral 
        ? "Average for this month." 
        : `${Math.abs(difference)} ${isNegative ? 'less' : 'more'} ${units} than average.`;

    const ArrowIcon = isNeutral ? RemoveIcon : (isNegative ? TrendingDownIcon : TrendingUpIcon);

    return (
        <Box sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Tooltip title={message} arrow>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color }}>
                    <ArrowIcon sx={{ fontSize: 48 }} />
                    {(!isNeutral) && (
                        <Box sx={{ fontSize: '.8rem', fontWeight: '600', textAlign: 'center' }}>
                            {difference} {units}
                        </Box>
                    )}
                </Box>
            </Tooltip>
        </Box>
    );
};

export default DiffIndicator;
