import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  Chip,
  Link,
  FormGroup,
  FormControlLabel,
  Switch,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Stack,
} from "@mui/material";
import {
  ErrorOutlineOutlined as ErrorOutlineOutlinedIcon,
  PaymentOutlined as PaymentOutlinedIcon,
} from "@mui/icons-material";
import stripeLogo from "./stripe-logo.png";
import EnergySavingsLeafTwoToneIcon from "@mui/icons-material/EnergySavingsLeafTwoTone";
import ParkTwoToneIcon from "@mui/icons-material/ParkTwoTone";
import ForestTwoToneIcon from "@mui/icons-material/ForestTwoTone";
import PaymentCard from "../../components/Subscribe/PaymentCard";
import PaymentProcessingModal from "../../components/Subscribe/PaymentProcessingModal";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

const PaymentModal = ({ colors }) => {
  const [standardQuantity, setStandardQuantity] = useState(1);
  const [advancedQuantity, setAdvancedQuantity] = useState(1);
  const [enterpriseQuantity, setEnterpriseQuantity] = useState(1);
  const [isAnnual, setIsAnnual] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentTier, setCurrentTier] = useState(null);
  const [currentQuantity, setCurrentQuantity] = useState(1);
  const tierPrices = {
    1: 25,
    2: 35,
    3: 50,
  };

  useEffect(() => {
    setCurrentTier(null);
    setCurrentQuantity(1);
  }, []);

  const handleProceed = async (tier, quantity) => {
    if (currentTier === tier) {
      setCurrentTier(null);
      setCurrentQuantity(1);
    } else {
      setCurrentTier(tier);
      setCurrentQuantity(quantity);
    }
  };

  const openPaymentModal = () => {
    setModalOpen(true);
  };

  const calculatePrice = (tier, quantity, isAnnual, tierPrices) => {
    const meterPrice = 5;
    let basePrice = tierPrices[tier] + meterPrice * quantity;

    if (isAnnual) {
      basePrice *= 12 * 0.8; // 20% discount for annual pricing
    } else {
      basePrice *= 1; // Monthly pricing without discount
    }

    return basePrice;
  };

  const FeatureBullet = ({ description }) => (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="left"
      marginBottom="8px"
    >
      <RadioButtonCheckedIcon sx={{ color: colors.greenAccent[600] }} />
      <Typography variant="body2" marginLeft="8px">
        {description}
      </Typography>
    </Box>
  );

  const FeatureList = ({ tierLevel }) => {
    const features = [
      "Meter Data view and export for all meters.",
      "Meter Readings view and export for all arranged meters.",
      "Online platform / data support.",
      `${
        tierLevel === 1 ? "One" : tierLevel === 2 ? "Up to five" : "Unlimited"
      } Site Locations.`,
      "Unlimited meter alarms.",
      "Unlimited push notifications.",
      tierLevel > 1 &&
        `${tierLevel === 2 ? "Three" : ""} ${
          tierLevel === 3 ? "Unlimited" : ""
        } sub-users.`,
      tierLevel > 1 && "Unlimited Location Mapping.",
      tierLevel > 1 && "White Labelling.",
      tierLevel > 2 && "Automated Reports.",
      tierLevel > 2 && "API Access (Subject to billing).",
    ].filter(Boolean); // Filter out falsy values for tier-specific features

    return (
      <>
        {features.map((description, index) => (
          <FeatureBullet key={index} description={description} />
        ))}
      </>
    );
  };

  const price = currentTier
    ? calculatePrice(currentTier, currentQuantity, isAnnual, tierPrices)
    : 0;

  return (
    <Box display="flex" p={1} width="100%" m={2}>
      <Box flex={3} pr={2} display="flex" flexDirection="column" height="100%">
        <Box
          pb={2}
          display="flex"
          width="100%"
          flex="1"
          justifyContent="center"
          gap="16px"
        >
          <PaymentCard
            tierPrices={tierPrices}
            title="Standard"
            subheader="Our essential features in our most basic package."
            quantity={standardQuantity}
            setQuantity={setStandardQuantity}
            handleProceed={() => handleProceed(1, standardQuantity)}
            colors={colors}
            icon={
              <EnergySavingsLeafTwoToneIcon
                fontSize="inherit"
                sx={{ color: "white" }}
                height="100%"
              />
            }
            body={<FeatureList tierLevel={1} />}
            tier={1}
            isAnnual={isAnnual}
            selected={currentTier === 1}
          />
          <PaymentCard
            title="Advanced"
            tierPrices={tierPrices}
            subheader="Our more advanced package for growing businesses."
            quantity={advancedQuantity}
            setQuantity={setAdvancedQuantity}
            handleProceed={() => handleProceed(2, advancedQuantity)}
            colors={colors}
            icon={
              <ParkTwoToneIcon
                fontSize="inherit"
                sx={{ color: "white" }}
                height="100%"
              />
            }
            body={<FeatureList tierLevel={2} />}
            tier={2}
            isAnnual={isAnnual}
            selected={currentTier === 2}
          />
          <PaymentCard
            title="Enterprise"
            tierPrices={tierPrices}
            subheader="The complete package for large scale solutions."
            quantity={enterpriseQuantity}
            setQuantity={setEnterpriseQuantity}
            handleProceed={() => handleProceed(3, enterpriseQuantity)}
            colors={colors}
            icon={
              <ForestTwoToneIcon
                fontSize="inherit"
                sx={{ color: "white" }}
                height="100%"
              />
            }
            body={<FeatureList tierLevel={3} />}
            tier={3}
            isAnnual={isAnnual}
            selected={currentTier === 3}
          />
          <Paper
            elevation={10}
            sx={{
              backgroundColor: colors.grey[900],
              color: colors.grey[100],
              marginBottom: "16px",
              flex: "1",
              display: "flex",
              flexDirection: "column",
              minWidth: "24%",
              maxWidth: "24%",
              height: "100%",
            }}
          >
            <Stack
              spacing={1}
              p={2}
              minHeight="35vh"
              flex="1"
              justifyContent="space-between"
            >
              <Box>
                <Typography variant="h4" gutterBottom>
                  {currentTier ? "Cost Summary" : "Select a Tier"}
                </Typography>
                {currentTier ? (
                  <Table maxWidth="100%" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Desc</TableCell>
                        <TableCell align="right">Occurrence</TableCell>
                        <TableCell align="right">Price</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          {isAnnual
                            ? "1 Year MGV Subscription"
                            : "1 Month MGV Subscription"}
                        </TableCell>
                        <TableCell align="right">
                          {isAnnual ? "P/A" : "P/M"}
                        </TableCell>
                        <TableCell align="right">£{price.toFixed(2)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{currentQuantity} Meters</TableCell>
                        <TableCell align="right">
                          {isAnnual ? "P/A" : "P/M"}
                        </TableCell>
                        <TableCell align="right">
                          £{(currentQuantity * 5).toFixed(2)}
                        </TableCell>
                      </TableRow>
                      {isAnnual && (
                        <TableRow>
                          <TableCell />
                          <TableCell>Discount 20%</TableCell>
                          <TableCell align="right">
                            -{(price * 0.2).toFixed(2)}
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell />
                        <TableCell>Tax</TableCell>
                        <TableCell align="right">
                          £{(price * 0.2).toFixed(2)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell />
                        <TableCell>Total</TableCell>
                        <TableCell align="right">
                          £{(price * 1.2).toFixed(2)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                ) : (
                  <Typography variant="body1">
                    Please select a tier to see the cost summary.
                  </Typography>
                )}
              </Box>
              <Box
                mt="auto"
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                <Chip
                  icon={<ErrorOutlineOutlinedIcon color="inherit" />}
                  label="20% Discount on Annual Pricing."
                  width="100%"
                  sx={{ marginBottom: "10px" }}
                />
                <Box
                  display="flex"
                  flexDirection="row"
                  width="100%"
                  sx={{
                    backgroundColor: colors.greenAccent[500],
                    marginBottom: "10px",
                  }}
                  borderRadius="5px"
                  elevation={6}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                    alignItems="center"
                    p={1}
                  >
                    <FormGroup>
                      <FormControlLabel
                        color={colors.greenAccent[600]}
                        control={
                          <Switch onChange={() => setIsAnnual(!isAnnual)} />
                        }
                        label={isAnnual ? "Annual" : "Monthly"}
                      />
                    </FormGroup>
                  </Box>
                </Box>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={openPaymentModal}
                  startIcon={currentTier ? <PaymentOutlinedIcon /> : <></>}
                  fullWidth
                  disabled={!currentTier}
                >
                  {currentTier ? "Proceed" : "Select a tier"}
                </Button>
              </Box>
            </Stack>
          </Paper>
        </Box>
        <Chip
          icon={<ErrorOutlineOutlinedIcon color="inherit" />}
          sx={{
            backgroundColor: "rgb(107,96,255)",
            color: "white",
            width: "100%",
            textAlign: "left",
            justifyContent: "left",
          }}
          label={
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <Box display="flex" alignItems="center">
                <Typography variant="body1">
                  Payments are processed through
                </Typography>
                <img
                  src={stripeLogo}
                  alt="Stripe"
                  style={{ marginLeft: 6, maxWidth: "50px" }}
                />
              </Box>
              <Link
                href="https://stripe.com/gb/legal/ssa"
                color="inherit"
                variant="body1"
                style={{ marginLeft: 6 }}
              >
                {" "}
                Read their terms and conditions here.
              </Link>
            </Box>
          }
        />
      </Box>

      <PaymentProcessingModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        loading={loading}
        setLoading={setLoading}
        tier={currentTier}
        quantity={currentQuantity}
        isAnnual={isAnnual}
        price={price}
      />
    </Box>
  );
};

export default PaymentModal;
