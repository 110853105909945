import React, { useEffect, useRef } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import { useTheme } from "@mui/material";

const transformData = (data) => {
  try {
    console.log("Transforming data: ", data);
    const parsedData = JSON.parse(data.body);
    return parsedData.MeterData.map(item => {
      const baseTimestamp = new Date(item.TimeStamp).getTime();
      const intervalMinutes = (item.IntervalNo - 1) * 30; // each interval is 30 minutes
      const exactTimestamp = baseTimestamp + intervalMinutes * 60 * 1000; // convert minutes to milliseconds
      return [exactTimestamp, item.MultipliedConsumption];
    });
  } catch (error) {
    console.error('Error parsing meter Summary data:', error);
    return [];
  }
};

function hexToRgba(hex, opacity) {
  // Remove the hash symbol if present
  hex = hex.replace(/^#/, '');

  // Parse the r, g, b values
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Return the RGBA string
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}
  
  const HighchartsComponent = ({ data, colors }) => {
    const theme = useTheme();
    const chartRef = useRef(null);
  
    useEffect(() => {
      if (data && chartRef.current) {
        const seriesData = transformData(data);
        const chart = chartRef.current.chart;
  
        if (chart.series[0]) {
          chart.series[0].setData(seriesData, true);
        } else {
          chart.addSeries({
            name: 'Consumption',
            data: seriesData,
            tooltip: {
              valueDecimals: 2
            }
          });
        }
      }
    }, [data, theme.palette.mode]);
  
    const chartOptions = {
      rangeSelector: {
          enabled: false,
          inputEnabled: false
      },
      chart: {
        backgroundColor: 'rgba(0,0,0,0)',
      },
      zoomType: 'none',
      title: {
        text: 'Month to Date',
        style: {
            color: colors.grey[100]
        }
      },
      navigator: {
        maskFill: hexToRgba(colors.greenAccent[400], 0.30),
        series: {
          color: colors.greenAccent[500]
        }
      },
      xAxis: {
        labels: {
            style: {
                color: colors.grey[100]
            }
        }
      },
      yAxis: {
          title: {
            text: 'Energy (kWh)',
            style: {
              color: colors.grey[100]
          }
        },
          labels: {
            style: {
                color: colors.grey[100]
            }
        }
        },
        tooltip: {
          valueSuffix: 'kWh'
        },
      series: [{
        color: colors.greenAccent[500],
        name: '',
        data: [],
        tooltip: {
          valueDecimals: 2
        }
      }]
    };
  
    return (
      <HighchartsReact
          width="100%"
        highcharts={Highcharts}
        constructorType={'stockChart'}
        options={chartOptions}
        ref={chartRef}
      />
    );
  };
  
  export default HighchartsComponent;