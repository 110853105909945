import { fetchAuthSession } from 'aws-amplify/auth';
import notification from '../components/Global/Toast';

export const saveColorMode = async (mode, lastChange, setLastChange) => {
  const now = Date.now();
  
  if (now - lastChange < 0) {  // Rate limit can be set here (ms)
    return;
  }
  setLastChange(now);

  try {
    const session = await fetchAuthSession();

    if (!session || !session.tokens || !session.tokens.accessToken) {
      console.error('Invalid session or access token.');
      notification('warning', "Failed to save colour mode", mode);
      return;
    }

    const accessToken = session.tokens.accessToken;

    const response = await fetch('https://www.data.mygreenvision.net/SaveColorMode', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify({ ColorMode: mode })
    });

    if (!response.ok) {
      notification('warning', "Failed to save colour mode", mode);
      throw new Error('Failed to update color mode');
    }

    const result = await response.json();
    console.log('Color mode updated successfully:', result);
    notification(mode,("Welcome to ").concat(mode, " mode."), mode);
  } catch (error) {
    notification('warning', "Failed to save colour mode", mode);
    console.error('Error updating color mode: ', error);
  }
};
