import React, { useState, useEffect, useRef, createContext } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import Topbar from './scenes/global/Topbar';
import Sidebar from './scenes/global/Sidebar';
import Meters from './scenes/Meters/index.jsx';
import Meter from './data/meterQuery';
import MeterData from './scenes/MeterData/index.jsx';
import MeterReadings from './scenes/MeterReadings/index.jsx';
import Alerts from './scenes/Alerts/index.jsx';
import Preferences from './scenes/Preferences/index.jsx';
import Map from './scenes/Map/index.jsx';
import Admin from './scenes/Admin/index.jsx'
import FAQ from './scenes/faq/index.jsx';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { Box } from '@mui/material';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import MeterMenu from './scenes/global/MeterMenu.jsx';
import DeviceCheck from './DeviceCheck';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { saveColorMode } from './data/saveColorMode.js';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { tokens, useMode, ColorModeContext } from './theme';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PaymentModal from './scenes/Subscribe/index.jsx';
import { useNavigate } from 'react-router-dom';
import Subusers from './scenes/Subusers/index.jsx';

Amplify.configure(awsconfig);

const stripePromise = loadStripe('pk_test_51PWIsOP0P3czJNK9A7nUvxykGMDb8rXQnNsAJffkZDkQIpT0S3tbJnjIATewfNrfF0cCZgnQiOCt7Nwo5BDQPzMi00ZzihK48d');

export const SubscriptionContext = createContext();

function App() {
  const [swatch, setSwatch] = useState(null); 
  const [theme, colorMode] = useMode(swatch);
  const [isSidebar, setIsSidebar] = useState(true);
  const location = useLocation();
  const [meterVariable, setMeterVariable] = useState('initial value');
  const [meterDataVariable, setMeterDataVariable] = useState('initial value');
  const [meterReadingsVariable, setMeterReadingsVariable] = useState('initial value');
  const [meterSummaryDataVariable, setMeterSummaryDataVariable] = useState(null);
  const [isSummaryLoading, setSummaryLoading] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isAttributesLoaded, setIsAttributesLoaded] = useState(false); // New state for attribute loading

  const lastMeterSummaryId = useRef(-2);

  const navigate = useNavigate();

  const colors = tokens(theme.palette.mode || 'light', swatch);

  const updateMeterVariable = async (Value) => {
    const meterTreeId = Value.parent.children[Value.index].meterTreeId;

    if (meterVariable !== meterTreeId) {
      if (!Value.isParent && Value.parent.children[Value.index].meterTreeId !== lastMeterSummaryId.current) {
        setMeterSummaryDataVariable(null);
        setSummaryLoading(true);

        setMeterVariable(meterTreeId);

        try {
          const data = await handleFetchMeter(meterTreeId);
          if (JSON.parse(data.body).MeterData.length > 0) {
            setMeterSummaryDataVariable(data);
          } else {
            console.error('No Meter with that id?');
          }
        } catch (error) {
          console.error('Error updating meter variable:', error);
        } finally {
          setSummaryLoading(false);
        }
      } else {
        console.log('Selected Node is a Folder.');
      }
    } else {
      console.log('Selected Node has not changed.');
    }
  };

  const updateMeterDataVariable = async (Value) => {
    setMeterDataVariable(Value);
  };

  const updateMeterReadingsVariable = async (Value) => {
    setMeterReadingsVariable(Value);
  };

  const handleFetchMeter = async (meterTreeId) => {
    try {
      const data = await Meter.fetchMeter(meterTreeId);
      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };

  async function initializeColorMode() {
    try {
      const userAttributes = await fetchUserAttributes();
      const initColorMode = userAttributes['custom:colorMode'];
      const userSwatch = userAttributes['custom:swatch'];
      const subscriptionStatus = userAttributes['custom:subscription_status'];

      if (initColorMode && theme.palette.mode !== initColorMode) {
        colorMode.setMode(initColorMode);
      }
      setSwatch(userSwatch || "#388950");
      setIsSubscribed(subscriptionStatus === "active");
      setIsAttributesLoaded(true); // Set the attribute loading state to true
    } catch (error) {
      console.error('Error initializing color mode:', error);
      setIsAttributesLoaded(true); // Ensure this is set to true even on error
    }
  }

  useEffect(() => {
    initializeColorMode();
  }, []);

  useEffect(() => {
    if (isAttributesLoaded) {
      if (isSubscribed) {
        navigate('/meters');
      } else {
        navigate('/subscribe');
      }
    }
  }, [isAttributesLoaded, isSubscribed]);

  useEffect(() => {
    console.log("Theme updated:", theme);
    console.log("Colors:", colors);
  }, [theme, colors]);

  return (
    <SubscriptionContext.Provider value={{ isSubscribed, setIsSubscribed }}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app" style={{ overflow: 'hidden', width: '100vw', height: '100vh' }}>
            <Sidebar isSidebar={isSidebar} colors={colors} />
            <main className="content" style={{ overflow: 'hidden', height: '100vh' }}>
              <Topbar setIsSidebar={setIsSidebar} saveColorMode={saveColorMode} colors={colors} />
              <Box display="flex" p="0" width="100%" height="95%" style={{ overflow: 'hidden' }}>
                {(location.pathname !== '/' && location.pathname !== '/faq' && location.pathname !== '/subscribe' && location.pathname !== '/preferences' && location.pathname !== '/map' && location.pathname !== '/alerts' && location.pathname !== '/alerts' && location.pathname !== '/admin' ) && (
                  <MeterMenu
                    updateMeterReadingsVariable={updateMeterReadingsVariable}
                    updateMeterVariable={updateMeterVariable}
                    updateMeterDataVariable={updateMeterDataVariable}
                    colors={colors}
                  />
                )}
                {isAttributesLoaded ? (
                  <Routes>
                    <Route path="/" element={<Navigate colors={colors} to={isSubscribed ? "/meters" : "/subscribe"} />} />
                    <Route path="/meters" element={isSubscribed ? <Meters colors={colors} isSummaryLoading={isSummaryLoading} meterSummaryDataVariable={meterSummaryDataVariable} style={{ width: '100%', height: '100%', overflow: 'hidden' }} /> : <Navigate to="/subscribe" />} />
                    <Route path="/meterdata" element={isSubscribed ? <MeterData colors={colors} meterDataVariable={meterDataVariable} style={{ width: '100%', height: '100%', overflow: 'hidden' }} /> : <Navigate to="/subscribe" />} />
                    <Route path="/meterreadings" element={isSubscribed ? <MeterReadings colors={colors} style={{ width: '100%', height: '100%', overflow: 'hidden' }} /> : <Navigate to="/subscribe" />} />
                    <Route path="/alerts" element={isSubscribed ? <Alerts colors={colors} style={{ width: '100%', height: '100%', overflow: 'hidden' }} /> : <Navigate to="/subscribe" />} />
                    <Route path="/map" element={isSubscribed ? <Map colors={colors} style={{ width: '100%', height: '100%', overflow: 'hidden' }} /> : <Navigate to="/subscribe" />} />
                    <Route path="/admin" element={isSubscribed ? <Admin colors={colors} style={{ width: '100%', height: '100%', overflow: 'hidden' }} /> : <Navigate to="/subscribe" />} />
                    <Route path="/preferences" element={isSubscribed ? <Preferences colors={colors} style={{ width: '100%', height: '100%', overflow: 'hidden' }} /> : <Navigate to="/subscribe" />} />
                    <Route path="/faq" element={<FAQ colors={colors} style={{ width: '100%', height: '100%', overflow: 'hidden' }} />} />
                    <Route path="/subscribe" element={<PaymentModal colors={colors} />} />
                    <Route path="/subusers" element={isSubscribed ? <Subusers colors={colors} style={{ width: '100%', height: '100%', overflow: 'hidden' }} /> : <Navigate to="/subusers" />} />
                  </Routes>
                ) : (
                  <div>Loading...</div>
                )}
              </Box>
            </main>
          </div>
          <ToastContainer />
        </ThemeProvider>
      </ColorModeContext.Provider>
    </SubscriptionContext.Provider>
  );
}

const WrappedApp = withAuthenticator(App);

export default ({ colors }) => (
  <DeviceCheck>
      <Elements stripe={stripePromise}>
        <WrappedApp />
      </Elements>
  </DeviceCheck>
);
