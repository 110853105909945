import { faFolder, faSun, faPlug, faDroplet, faTemperatureThreeQuarters, faPercent, faToggleOn, faPersonShelter, faGasPump, faCloud, faFireFlameSimple, faOilCan, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'font-awesome/css/font-awesome.min.css';

const FindIcon = ({ utilityId, utilitySubtypeId, colors }) => {
    const utilIcons = {
        100: <FontAwesomeIcon icon={faFolder} style={{ color: colors.greenAccent[400] }} />,
        0: <FontAwesomeIcon icon={faExclamation} style={{ color: colors.redAccent[400] }} />,
        1: <FontAwesomeIcon icon={faPlug} style={{ color: colors.primary[200] }} />,
        2: <FontAwesomeIcon icon={faFireFlameSimple} style={{ color: colors.blueAccent[600] }} />,
        3: <FontAwesomeIcon icon={faFireFlameSimple} style={{ color: colors.blueAccent[600] }} />,
        4: <FontAwesomeIcon icon={faDroplet} style={{ color: colors.blueAccent[400] }} />,
        5: <FontAwesomeIcon icon={faPercent} style={{ color: colors.primary[200] }} />,
        6: <FontAwesomeIcon icon={faTemperatureThreeQuarters} style={{ color: colors.redAccent[700] }} />,
        7: <FontAwesomeIcon icon={faToggleOn} style={{ color: colors.primary[200] }} />,
        8: <FontAwesomeIcon icon={faTemperatureThreeQuarters} style={{ color: colors.redAccent[700] }} />,
        9: <FontAwesomeIcon icon={faDroplet} style={{ color: colors.primary[200] }} />,
        10: <FontAwesomeIcon icon={faFireFlameSimple} style={{ color: colors.primary[200] }} />,
        11: <FontAwesomeIcon icon={faSun} style={{ color: '#FEB56A' }} />,
        12: <FontAwesomeIcon icon={faPersonShelter} style={{ color: colors.primary[200] }} />,
        13: <FontAwesomeIcon icon={faGasPump} style={{ color: colors.primary[200] }} />,
        14: <FontAwesomeIcon icon={faGasPump} style={{ color: colors.primary[200] }} />,
        15: <FontAwesomeIcon icon={faGasPump} style={{ color: colors.primary[200] }} />,
        16: <FontAwesomeIcon icon={faCloud} style={{ color: colors.primary[200] }} />,
        17: <FontAwesomeIcon icon={faFireFlameSimple} style={{ color: colors.blueAccent[600] }} />,
        18: <FontAwesomeIcon icon={faCloud} style={{ color: colors.grey[400] }} />,
        19: <FontAwesomeIcon icon={faOilCan} style={{ color: colors.primary[200] }} />,
    };

    const utilSubIcons = {
        3: <FontAwesomeIcon icon={faSun} style={{ color: '#FEB56A' }} />,
        10: <FontAwesomeIcon icon={faSun} style={{ color: '#FEB56A' }} />,
    }

    if (utilitySubtypeId !== null && (utilitySubtypeId === 3 || utilitySubtypeId === 10)) {
        return utilSubIcons[utilitySubtypeId];
    } else {
        return utilIcons[utilityId];
    }
}

export default FindIcon;
