import React from 'react';
import { Box, Typography } from "@mui/material";

 
  const CurrentSelectedMeter = ({ data, colors }) => {
    const parsedData = JSON.parse(data.body);

    return (
      <Box p="10px">
        {(parsedData.AdditionalMeterData.length > 0) &&
          <Typography width="100%" component="div" fontWeight= "600" fontSize="1.4rem">{parsedData.AdditionalMeterData[0].MeterName ? parsedData.AdditionalMeterData[0].MeterName : "Undefined"}</Typography>
        }
      </Box>

    );
  };
  
  export default CurrentSelectedMeter;