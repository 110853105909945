import React, { useState, useEffect } from 'react';
import { Box, Typography } from "@mui/material";
import Header from "../../components/Global/Header";
import HighchartsComponent from '../../components/Meters/meterChart';
import SummaryMap from '../../components/Meters/summaryMap';
import AdditionalMeterDetails from '../../components/Meters/additionalMeterDetails';
import CurrentSelectedMeter from '../../components/Meters/currentSelectedMeter';
import Spinner from '../../components/Global/Spinner'

const Meters = ({ meterSummaryDataVariable, isSummaryLoading, colors }) => {
  const [mapState, setMapState] = useState(false);

  useEffect(() => {
    if (meterSummaryDataVariable) {
      const hasLat = JSON.parse(meterSummaryDataVariable.body).AdditionalMeterData[0].Lat;
      setMapState(hasLat);
    } else {
      setMapState(false);
    }
  }, [meterSummaryDataVariable]);

  return (
    <Box m="20px" width="80%" height="70vh">
      <Header title="Meters" subtitle="View Individual Meter Summary" colors={colors}/>
      <Box width="100%" height="100%" >
      {!isSummaryLoading ? (
          meterSummaryDataVariable ? (
            <Box>
              <CurrentSelectedMeter data={meterSummaryDataVariable} colors={colors} />
              <Box display="flex" width="100%">
                <Box width={mapState ? "50%" : "100%"}>
                  <AdditionalMeterDetails data={meterSummaryDataVariable} colors={colors} />
                  <HighchartsComponent data={meterSummaryDataVariable} colors={colors} />
                </Box>
                  <Box borderRadius="10px" width="100%" height="100%" overflow="hidden">
                    <SummaryMap data={meterSummaryDataVariable} colors={colors} />
                  </Box>
              </Box>
            </Box>
          ) : (
            <Typography>Select a Meter to Begin.</Typography>
          )
        ) : (
          <Box width="100%" height="100%" overflow="visible" display="flex" alignItems="center" justifyContent="center">
            <Spinner colors={colors} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Meters;
