import React, { useContext, useState } from 'react';
import { Box, IconButton, useTheme } from "@mui/material";
import { ColorModeContext } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useNavigate } from 'react-router-dom';

const Topbar = ({ saveColorMode, colors }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const [lastChange, setLastChange] = useState(null);

  const faqRedirect = () => {
    navigate('/faq');
  };
  
  const handleColorModeToggle = () => {
    const newMode = theme.palette.mode === "dark" ? "light" : "dark";
    colorMode.toggleColorMode();
    saveColorMode(newMode, lastChange, setLastChange);
  };

  return (
    <Box display="flex" justifyContent="space-between" pl="5px" height="5%">
      <Box
        display="flex"
        borderRadius="3px"
        alignItems="center"
      >
        <IconButton onClick={handleColorModeToggle}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton onClick={faqRedirect}>
          <InfoOutlinedIcon />
        </IconButton>
      </Box>

      <Box display="flex">
        
      </Box>
    </Box>
  );
};

export default Topbar;
