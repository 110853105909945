import { fetchUserAttributes } from 'aws-amplify/auth';
import { useTheme, Box } from '@mui/material';
import { ReactComponent as DarkLogo } from '../../scenes/global/MGV-Logo-Dark-SVG.svg';
import { ReactComponent as LightLogo } from '../../scenes/global/MGV-Logo-Light-SVG.svg';
import { useEffect, useState } from 'react';
import Spinner from './Spinner';

async function fetchLogoLink() {
    try {
        const userAttributes = await fetchUserAttributes();
        return userAttributes['custom:logoLink'];
    } catch (error) {
        return null;
    }
}

const Logo = ({ colors }) => {
    const theme = useTheme();
    const [logoLink, setLogoLink] = useState(null);
    const [logoLoading, setLogoLoading] = useState(true);

    useEffect(() => {
        const getLogoLink = async () => {
            setLogoLoading(true);
            const link = await fetchLogoLink();
            setLogoLink(link);
            setLogoLoading(false);
        };
        getLogoLink();
    }, []);

    return logoLoading ? (
        <Spinner colors={colors}/>
    ) : logoLink ? (
        <Box width="100%" height="100px" overflow="visible" display="flex" alignItems="center" justifyContent="center">
            <img 
                src={logoLink} 
                alt='whitelabel logo' 
                style={{ maxWidth: "90%", width: "auto", height: "auto", maxHeight: "100px", filter: "drop-shadow(2px 2px 4px rgba(0,0,0,0.2))" }}
            />
        </Box>
    ) : theme.palette.mode === "light" ? (
        <DarkLogo width="100%" />
    ) : (
        <LightLogo width="100%" />
    );
};

export default Logo;
