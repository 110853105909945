import { fetchAuthSession } from 'aws-amplify/auth';
import notification from '../components/Global/Toast';

const deleteSubUser = async (username) => {
  try {
    const session = await fetchAuthSession();
    const token = session.tokens.accessToken || session.tokens.idToken;
    console.log("Username: ", username)
    const response = await fetch("https://www.data.mygreenvision.net/SubUser/delete", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ 
        Username: username 
      }),
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(`Code: ${data.statusCode}`);
    }

    notification("success", "Sub-user deleted successfully");
    return data;
  } catch (error) {
    console.error("Error deleting sub-user:", error);
    notification("error", "Failed to delete sub-user");
    throw error;
  }
};

export default deleteSubUser;
