import { fetchAuthSession } from 'aws-amplify/auth';
import notification from '../components/Global/Toast';

const Alarms = {
  getAlarmData: async () => {
    console.log("Fetching Alarm Data...");
    try {
      const session = await fetchAuthSession();
      const token = session.tokens.accessToken || session.tokens.idToken;

      const url = 'https://www.data.mygreenvision.net/Alarms/';

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      });

      const data = await response.json();

      if (!response.ok || data.statusCode !== 200) {
        notification('error', "Failed to fetch alarm data");
        throw new Error("Code: ", data.statusCode);
      }
     
      console.log(data);
      //notification('success', 'Alarm data fetched successfully');
      return JSON.parse(data.body);

    } catch (error) {
      console.error('Error fetching alarm data:', error);
      notification('error', 'Failed to fetch alarm data');
      throw error;
    }
  }
};

export default Alarms;
