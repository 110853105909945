import { fetchAuthSession } from 'aws-amplify/auth';

const chunkArray = (array, chunkSize) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};

const MeterReadings = {
  fetchAllMeterReadings: async (meterTreeIDs, dateStamp) => {
    console.log("Fetching Meter Readings...", "MeterTreeID Array: ", meterTreeIDs, ", DateStamp: ", dateStamp);
    let combinedResults = {
      MeterData: [],
      AdditionalMeterDetails: []
    };
    try {
      const session = await fetchAuthSession();
      const token = session.tokens.accessToken || session.tokens.idToken;

      if (!token) {
        throw new Error('Authorization token not available');
      }

      const meterChunks = chunkArray(meterTreeIDs, 3); // Adjust chunk size as needed
      console.log("Length of MeterChunks Array: ", meterChunks.length);

      for (let i = 0; i < meterChunks.length; i++) {
        const chunk = meterChunks[i];
        const meterList = chunk.join(',');

        const url = new URL(`https://www.data.mygreenvision.net/meterreadings`);
        url.search = new URLSearchParams({
          datestamp: encodeURIComponent(dateStamp),
          meterList: meterList
        }).toString();

        const response = await fetch(url.toString(), {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          console.error(`HTTP error! status: ${response.status} for meterList: ${meterList}`);
          continue;
        }

        const data = await response.json();
        console.log(`Fetched data for meterList ${meterList}:`, data);

        // Parse and combine JSON results
        const parsedData = JSON.parse(data.body); // Parsing the body field of the response

        for (const key in parsedData) {
          if (parsedData.hasOwnProperty(key)) {
            if (!Array.isArray(combinedResults[key])) {
              combinedResults[key] = [];
            }
            combinedResults[key] = combinedResults[key].concat(parsedData[key]);
          }
        }
      }

      console.log('Combined Results:', combinedResults);
      return combinedResults;

    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted');
        console.log('Combined Results:', combinedResults);
        return combinedResults;
      } else {
        console.error('Error fetching meter data:', error);
        throw error;
      }
    }
  }
};

export default MeterReadings;
