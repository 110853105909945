import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import "jquery-ui-bundle";
import "jquery.fancytree/dist/skin-win7/ui.fancytree.css";
import "jquery.fancytree/dist/modules/jquery.fancytree.filter";
import "jquery.fancytree/dist/modules/jquery.fancytree.edit";
import "jquery.fancytree/dist/modules/jquery.fancytree.table";
import "jquery.fancytree/dist/modules/jquery.fancytree.dnd";
import "@fortawesome/fontawesome-free/css/all.css";
import { Box } from "@mui/material";
import Spinner from "../../components/Global/Spinner.jsx";
import { fetchAuthSession } from "aws-amplify/auth";
import findSVGIcon from "../../components/Global/utilitySVGIcons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fa1} from "@fortawesome/free-solid-svg-icons"; // import specific icons
import ReactDOMServer from "react-dom/server";

const EditTreeComponent = ({ colors }) => {
    const [treeData, setTreeData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const treeRef = useRef(null);
    const fancyTreeInstance = useRef(null);

    useEffect(() => {
        const fetchUserDataAndTree = async () => {
            setIsLoading(true);
            try {
                const session = await fetchAuthSession();
                const token = session.tokens.accessToken || session.tokens.idToken;
                const response = await fetch(
                    "https://www.data.mygreenvision.net/metertree",
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                const parsedData = JSON.parse(data.body);
                const formattedTreeData = buildTree(parsedData);
                setTreeData(formattedTreeData);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchUserDataAndTree();
    }, []);

    const buildTree = (items) => {
        const rootItemsMap = {};
        const lookup = {};

        items.forEach((item) => {
            const rootId = item["MeterTreesID"];
            const rootLabel = item["FriendlyName"] || "Unnamed Root";

            if (!rootItemsMap[rootId]) {
                rootItemsMap[rootId] = {
                    title: rootLabel,
                    key: rootId,
                    folder: true,
                    children: [],
                };
            }

            lookup[item["MeterTreeID"]] = { ...item, children: [] };
        });

        items.forEach((item) => {
            const itemId = item["MeterTreeID"];
            const parentId = item["ParentID"];
            const itemLabel = item["AltText"] || item["MeterName"] || "Unnamed";
            const rootId = item["MeterTreesID"];
            const meterTreeId = item["MeterTreeID"];
            const icon = findSVGIcon(
                item["UtilityID"],
                item["UtilitySubtypeID"]
            );

            const newItem = {
                title: itemLabel,
                key: itemId,
                icon: icon ? icon.iconName : null,
                folder: item["ChildCount"] > 0,
                meterTreeId: meterTreeId,
                utility: item["Utility"] || "",
                autoOpen: item["AutoOpen"] || "0",
                cts: item["CTs"] || "",
                msn: item["SerialNumber"] || "",
                multiplier: item["Multiplier"] || "1",
                hidden: item["Hidden"] || "0",
                UtilityID: item["UtilityID"] ? parseInt(item["UtilityID"]) : 0,
                UtilitySubtypeID: item["UtilitySubtypeID"]
                    ? parseInt(item["UtilitySubtypeID"])
                    : null,
            };

            lookup[itemId] = { ...lookup[itemId], ...newItem };

            if (!parentId) {
                rootItemsMap[rootId].children.push(lookup[itemId]);
            } else {
                if (lookup[parentId]) {
                    lookup[parentId].children.push(lookup[itemId]);
                }
            }
        });

        const sortChildren = (node) => {
            if (node.children && node.children.length > 0) {
                node.children.sort((a, b) => a.title.localeCompare(b.title));
                node.children.forEach(sortChildren);
            }
        };

        const sortedRootNodes = Object.values(rootItemsMap).sort((a, b) =>
            a.title.localeCompare(b.title)
        );
        sortedRootNodes.forEach(sortChildren);

        return sortedRootNodes;
    };

    const sortEntireTree = (treeData) => {
        const sortChildren = (node) => {
            if (node.children && node.children.length > 0) {
                node.children.sort((a, b) => a.title.localeCompare(b.title));
                node.children.forEach(sortChildren);
            }
        };

        if (!treeData || treeData.length === 0) return [];
        const sortedTreeData = treeData.sort((a, b) =>
            a.title.localeCompare(b.title)
        );
        sortedTreeData.forEach(sortChildren);
        return sortedTreeData;
    };

    useEffect(() => {
        if (!isLoading && treeData.length > 0) {
            if (treeRef.current) {
                if (fancyTreeInstance.current) {
                    $(treeRef.current).fancytree("destroy");
                }

                fancyTreeInstance.current = $(treeRef.current).fancytree({
                    extensions: ["table", "filter", "edit", "dnd"],
                    source: treeData,
                    checkbox: false,
                    table: {
                        checkboxColumnIdx: 0,
                        indentation: 16,
                        nodeColumnIdx: 0,
                    },
                    renderColumns: renderColumns,
                    dnd: {
                        autoExpandMS: 1500,
                        preventRecursion: true,
                        preventVoidMoves: true,
                        dragStart: (node, data) => {
                            if (!node.parent || node.parent.isRootNode()) {
                                return false;
                            }
                            data.effectAllowed = "all";
                            return true;
                        },
                        dragDrag: (node, data) => {
                            document.body.style.cursor = "move";
                        },
                        dragEnd: (node, data) => {
                            document.body.style.cursor = "default";
                        },
                        dragEnter: (node, data) => {
                            if (node.isRootNode()) {
                                return false;
                            }
                            return true;
                        },
                        dragOver: (node, data) => {
                            data.dropEffect = data.dropEffectSuggested;
                        },
                        dragDrop: (node, data) => {
                            if (data.otherNode) {
                                if (node.folder) {
                                    data.otherNode.moveTo(node, "child");
                                    node.setExpanded();
                                }
                            }
                            const newTreeData = sortEntireTree(
                                fancyTreeInstance.current
                                    .fancytree("getTree")
                                    .toDict(true).children
                            );
                            fancyTreeInstance.current.fancytree(
                                "option",
                                "source",
                                newTreeData
                            );
                            setTreeData(newTreeData); // Update treeData state

                            fancyTreeInstance.current
                                .fancytree("getTree")
                                .visit((childNode) => {
                                    const indent =
                                        (childNode.getLevel() - 1) * 16;
                                    $(childNode.tr)
                                        .find(">td")
                                        .eq(0)
                                        .css("padding-left", `${indent}px`);
                                });
                        },
                        dragLeave: (node, data) => {},
                    },
                });

                $(treeRef.current)
                    .fancytree("getTree")
                    .visit((node) => {
                        node.setExpanded(true, {
                            noAnimation: true,
                            noEvents: true,
                            noRender: false,
                        });
                    });
            }
        }
    }, [isLoading, treeData]);

    const renderColumns = (event, data) => {
        const node = data.node;
        const $tdList = $(node.tr).find(">td");

        const indent = (node.getLevel() - 1) * 16;

        const iconElement = node.data.icon ? (
            <FontAwesomeIcon icon={fa1} /> // use imported icon
        ) : (
            ""
        );

        $tdList.eq(0).css("padding-left", `${indent}px`);
        $tdList
            .eq(0)
            .html(
                `<span>${ReactDOMServer.renderToString(iconElement)}${
                    node.title
                }</span>`
            );

        $tdList.eq(1).text(node.data.UtilityID || "");
        $tdList.eq(2).text(node.data.autoOpen || "");
        $tdList.eq(3).text(node.data.cts || "");
        $tdList.eq(4).text(node.data.msn || "");
        $tdList.eq(5).text(node.data.multiplier || "");
        $tdList.eq(6).text(node.data.hidden || "");
    };

    return (
        <Box
            width="100%"
            height="100%"
            sx={{ backgroundColor: colors.primary[400], overflow: "hidden" }}
        >
            <Box
                maxWidth="100%"
                display="flex"
                height="92%"
                maxHeight="72vh"
                overflow="auto"
                justifyContent="left"
                flexGrow="1"
                sx={{ overflowY: "scroll", overflowX: "hidden" }}
            >
                {isLoading ? (
                    <Box width="100%" height="72vh" display="flex" alignItems="center" justifyContent="center">
                        <Spinner colors={colors} />
                    </Box>
                ) : (
                    <table
                        id="tree"
                        ref={treeRef}
                        className="fancytree-ext-table"
                        style={{ width: "100%", userSelect: "none" }}
                    >
                        <thead
                            style={{
                                position: "sticky",
                                top: 0,
                                backgroundColor: colors.primary[400],
                                zIndex: 1,
                            }}
                        >
                            <tr>
                                <th sx={{ padding: "0 5px 0 5px" }}>
                                    Folder/Meter Name
                                </th>
                                <th sx={{ padding: "0 5px 0 5px" }}>Utility</th>
                                <th sx={{ padding: "0 5px 0 5px" }}>
                                    Auto‑Open / Device Serial
                                </th>
                                <th sx={{ padding: "0 5px 0 5px" }}>CTs</th>
                                <th sx={{ padding: "0 5px 0 5px" }}>MSN</th>
                                <th sx={{ padding: "0 5px 0 5px" }}>
                                    Multiplier
                                </th>
                                <th sx={{ padding: "0 5px 0 5px" }}>Hidden</th>
                            </tr>
                        </thead>
                        <tbody>{/* Fancytree will render here */}</tbody>
                    </table>
                )}
            </Box>
        </Box>
    );
};

export default EditTreeComponent;
