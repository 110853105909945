import React from "react";
import {
  Box,
  Paper,
  Typography,
  Button,
  Stack,
  Tooltip,
  TextField,
  Divider,
} from "@mui/material";
import { PaymentOutlined, Check as CheckIcon } from "@mui/icons-material";

const PaymentCard = ({
  title,
  subheader,
  quantity,
  setQuantity,
  handleProceed,
  colors,
  icon,
  body,
  tier,
  isAnnual,
  tierPrices,
  selected,
}) => {
  return (
    <Paper
      elevation={10}
      sx={{
        margin: "0",
        flex: 1,
        minWidth: "24%",
        backgroundColor: selected ? colors.grey[800] : colors.grey[900],
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Paper
          width="100%"
          elevation={3}
          sx={{ backgroundColor: colors.greenAccent[500] }}
          borderRadius="5px"
        >
          <Box
            sx={{
              color: colors.grey[900],
              display: "flex",
              alignItems: "center",
              minHeight: "90px"
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: "auto",
                fontSize: "3rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "9px",
              }}
            >
              {icon}
            </Box>
            <Box padding="5px 5px 0 5px" flexGrow={1}>
              <Typography mb="2px" variant="h5" color="white">
                {title} £{isAnnual ? ((tierPrices[tier] * 12) * 0.8) : (tierPrices[tier])} {isAnnual ? "P/A" : "P/M"}
              </Typography>
              <Typography mb="5px" variant="body2">
                {subheader}
              </Typography>
            </Box>
          </Box>
        </Paper>
        <Stack spacing={1} p={2} minHeight="35vh">
          <Box>{body}</Box>
        </Stack>
      </Box>
      <Stack spacing={2} p={2}>
        <Tooltip title="Automatic">
          <TextField
            disabled
            type="number"
            label="Meter Quantity"
            variant="filled"
            value={quantity}
            onChange={(e) => setQuantity(Number(e.target.value))}
            fullWidth
            size="small"
          />
        </Tooltip>

        <Divider />

        <Button
          variant="contained"
          color="secondary"
          onClick={handleProceed}
          startIcon={selected ? <CheckIcon /> : <PaymentOutlined />}
          fullWidth
        >
          {selected ? "Selected" : "Select"}
        </Button>
      </Stack>
    </Paper>
  );
};

export default PaymentCard;
