import { fetchAuthSession } from 'aws-amplify/auth';
import notification from '../components/Global/Toast';

const AddLocationGroups = {
  addGroup: async (altText, lat, lng) => {
    console.log("Adding Location Group...");
    try {
      //console.log(altText, lat, lng);
      const session = await fetchAuthSession();
      const token = session.tokens.accessToken || session.tokens.idToken;

      const url = 'https://www.data.mygreenvision.net/AddLocationGroup/';

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          AltText: altText,
          Lat: lat,
          Lng: lng,
        }),
      });

      const data = await response.json();

      if (!response.ok || data.statusCode !== 200) {
        notification('error', "Failed to add location");
        throw new Error("Code: ", data.statusCode);
      }
     
      console.log(data);
      notification('success', 'Location group added successfully');
      return data;

    } catch (error) {
      console.error('Error adding location group:', error);
      //notification('error', 'Failed to add location');
      throw error;
    }
  }
};

export default AddLocationGroups;
