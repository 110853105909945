const Permissions = {
    permissionsTable: {
      1: "Administrator",
      2: "Editor",
      3: "Company Admin",
      4: "User",
      5: "Profile but No Reads",
    },
  
    permissionsTranslate: (id) => {
      return Permissions.permissionsTable[id];
    },
  };
  
  export default Permissions;
  