import React, { useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardHeader,
  Button,
  TextField,
  InputAdornment,
} from "@mui/material";
import Header from "../../components/Global/Header";
import Logo from "../../components/Global/logo";
import { HexColorPicker } from "react-colorful";
import TagIcon from "@mui/icons-material/Tag";

const Preferences = ({ colors }) => {
  const [currentColor, setCurrentColor] = useState(colors.greenAccent[500]);
  const [colorField, setColorField] = useState(currentColor);
  const [newLogo, setNewLogo] = useState(null);

  const handleColorChange = (swatch) => {
    setCurrentColor(swatch);
    setColorField(swatch);
  };

  const handleColorFieldChange = (event) => {
    const newColor = `#${event.target.value}`;
    setColorField(newColor);
    if (/^#[0-9A-F]{6}$/i.test(newColor)) {
      setCurrentColor(newColor);
    }
  };

  const handleLogoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setNewLogo(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Box m="20px">
      <Header
        title="Preferences"
        subtitle="Change Your Account Preferences"
        colors={colors}
      />
      <Card elevation={12}>
        <CardHeader
          title="White-Labelling"
          sx={{ backgroundColor: colors.greenAccent[400] }}
        />
        <Box width="100%" sx={{ padding: "10px 40px" }}>
          <Typography variant="body2" mr="5px">
            Current Logo:{" "}
          </Typography>
          <Box width="100px" mb="10px">
            <Logo
              maxWidth="100px"
              height="auto"
              colors={colors}
              overflow="hidden"
            />
          </Box>

          <Typography variant="body2" mr="5px">
            New Logo:{" "}
          </Typography>
          {newLogo && (
            <Box width="100px" mb="10px">
              <img
                src={newLogo}
                alt="New Logo"
                style={{ maxWidth: "100px", height: "auto" }}
              />
            </Box>
          )}
          <Button
            variant="contained"
            component="label"
            sx={{ marginBottom: "10px" }}
          >
            Upload New Logo
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={handleLogoUpload}
            />
          </Button>

          <Box width="100%">
            <HexColorPicker
              color={currentColor}
              onChange={handleColorChange}
              width="100%"
              marginBottom="10px"
            />
            <TextField
              variant="standard"
              maxHeight="20px"
              height="20px"
              sx={{ backgroundColor: currentColor, borderRadius: "5px", width: "100%" }}
              value={colorField.replace("#", "")}
              onChange={handleColorFieldChange}
              InputProps={{
                maxLength: 6,
                startAdornment: (
                  <InputAdornment position="start" color="white">
                    <TagIcon color="white" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default Preferences;
