// googleMapsLoader.js
import { useJsApiLoader } from '@react-google-maps/api';

const apiOptions = {
  id: 'google-map-script',
  googleMapsApiKey: 'AIzaSyBWo3ppY4XlulMJtFgqK9hYk8cxvxrsuP4',
  libraries: ['places'],
};

export const useGoogleMapsLoader = () => {
  return useJsApiLoader(apiOptions);
};