import React from 'react';
import { CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomCircularProgress = styled(CircularProgress)(({ theme, colors }) => ({
    '&.MuiCircularProgress-colorPrimary': {
        color: `${colors.greenAccent[600]} !important`,
    },
}));

const Spinner = ({ colors }) => (
    <CustomCircularProgress colors={colors} />
);

export default Spinner;
